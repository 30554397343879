import React, {useState, useContext, useEffect} from 'react';
import { signIn, confirmSignIn } from 'aws-amplify/auth';
import { Link, useNavigate } from "react-router-dom";
import {Button, Form, Row, Col} from 'react-bootstrap';
import { useSnackbar } from 'notistack';
import { ProductContext } from '../../ProductProvider';
import logo from '../../assets/images/logo/COLLEGELOGO.jpeg';
import Preloader from '../../components/Preloader/Preloader';
/*const config = require('../../config.json');

const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;
*/

/*const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    contact: "",
    address1: "",
    address2: "",
  };*/

export default function LoginPage() {
    const { validateLogin, setSidebarStatus} = useContext(ProductContext);
    const { enqueueSnackbar } = useSnackbar();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [showNewPass, setShowNewPass] = useState(false);
    const [newPassword, setNewPassword]=useState('');
    const [loaded, setLoaded] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        validateLogin();
      
      //console.log("logged user : ", loggedUser);
    }, [])
  
  
  
    const handleLogin = async(event) => {
        event.preventDefault();
        try{
            setLoaded(false);
            if(username===""||password===""){
              enqueueSnackbar("Username and Password cannot be empty",{variant:'info'});
              setLoaded(true);
            }else{
         // await Auth.signIn(username, password)
         //const { isSignedIn, nextStep } = await signIn({ username, password });
         //console.log("isSignedIn :",isSignedIn );
         //console.log("nextStep :",nextStep );
         await signIn({ 
          username, 
          password,
         /* options: {
            authFlowType: "USER_PASSWORD_AUTH",
          },*/
          })
         .then((user)=>{
          //console.log("User" , user);
          if(!user.isSignedIn && user.nextStep.signInStep==="CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED"){
            //setUser(user);
            setShowNewPass(true);
            enqueueSnackbar("Set up your New Password",{variant:'info'});
          }else{  
            validateLogin();
         }
         setLoaded(true);
        })
         .catch((error)=>{
          //console.log("Error :", error);
          setLoaded(true);
          enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});
         })
    }
          }catch(error){
            //console.log("Error", error);
            setLoaded(true);
            enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});            
          }
      };

      const handleChangePassword =async(event)=>{
        event.preventDefault();
        try{
          setLoaded(false);
          //const { isSignUpComplete, nextStep } = await confirmSignUp({username,confirmationCode});
          const attributes = {
            email: username,
           // phone_number: '+11111111111'
           };
           await confirmSignIn({
            challengeResponse: newPassword,
          /*  options: {
              userAttributes: attributes
             }*/
           })
          .then((user) => {
            //console.log(localeuser);
            if(user.isSignedIn){
              validateLogin();
            }
            setSidebarStatus("Orginal");
          enqueueSnackbar("Authenticated Successfully!",{variant:'success'});
          navigate('/');
          setLoaded(true);
          //history.push(Routes.DashboardOverview.path);
        }).catch(e => {
          //console.log("Error :", e);
          setLoaded(true);
          enqueueSnackbar("Error Occured - " + e.message,{variant : 'warning'});
        });
      
        }catch(error){
          //console.log("Error :", error);
          setLoaded(true);
          enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});
        }
      }
     
  
  return (
    <>
    {!loaded && <Preloader show={loaded ? false : true} />}
    <div style={{padding:"50px", marginBottom:'auto'}}>
      <div className="col d-flex justify-content-center text-center">
        <div className="card">
          <div className="card__body">
        <h2>Vidyajyoti College of Theology</h2>
        <h2>Delhi</h2>
        <img
            src={logo}
            alt="logo"
            width="150"
            className="shadow-light rounded-circle" />
          <h4>Admin Office Login</h4>
          <div component="form">
            <Form>
            <Form.Group  as={Row} className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label column sm="4">Email address</Form.Label>
            <Col sm="8">
            <Form.Control type="email" placeholder="name@example.com"
                          autoFocus disabled={showNewPass}
                          onChange={event=>setUsername(event.target.value)}
                          value={username} />
            </Col>
          </Form.Group>
          </Form>
            {!showNewPass ?
                  <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                  <Form.Label column sm="4">
                    Password
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control type="password" placeholder="Password"               
                    onChange={event=>setPassword(event.target.value)}
              value={password}/>
                  </Col>
                </Form.Group>:
            <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
            <Form.Label column sm="2">
              Password
            </Form.Label>
            <Col sm="10">
              <Form.Control type="password" placeholder="Password"               
              onChange={event=>setNewPassword(event.target.value)}
              value={newPassword}/>
            </Col>
          </Form.Group>}
          {!showNewPass? <Button
            type="submit"
            variant="primary"
            onClick={event=>{handleLogin(event)}}>
            Sign In
          </Button>:<Button
            type="submit"
            variant="primary"
            onClick={event=>{handleChangePassword(event)}}>
              Change Password
          </Button>}
            <div className='container'>
              <div>
                <Link to="/forgot-password" variant="body2">
                  Forgot password?
                </Link>
              </div>
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  </>
  )
}
