import React, {useState, useContext, useEffect} from 'react';
import MarkSheetForm from './MarkSheetForm';
import { Button, Dropdown, Table, Breadcrumb, Modal, Row, Col, Form , ButtonGroup, Card} from 'react-bootstrap';
import { useSnackbar } from 'notistack';
import {Helmet} from 'react-helmet-async';
import { fetchAuthSession } from 'aws-amplify/auth';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { faPlus, faEdit, faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import { Link } from "react-router-dom";
import axios from 'axios';
import ReactToPrint from "react-to-print";
import { CSVLink } from "react-csv";
import { ProductContext } from '../../ProductProvider';
import { downloadHeaders } from '../../ProData';
import Preloader from '../../components/Preloader/Preloader';
const config = require('../../config.json');

export default function MarkSheet(props) {
    const { loggedUser, generateDate, validateLogin } = useContext(ProductContext);
    const {profileDetails} = props;
    const [courseDetails, setCourseDetails]=useState({courseCode:'', courseName:''});
    const [formType, setFormType] = useState("View");
    const [loaded, setLoaded] = useState(true);
    const [collapsed, setCollapsed] = useState(true);
    const csvLinkEl = React.createRef();
    const componentRef = React.useRef(null);
    //const [searchTopic, setSearchTopic] = useState('Batch');
    //const [searchValue, setSearchValue] = useState('');
    const [batchYear, setBatchYear]= useState([]);
    const [searchResults, setSearchResults] = useState([]);
    const [selectedBatch, setSelectedBatch]=useState('');
    const { enqueueSnackbar } = useSnackbar();


    useEffect(()=>{
        if(loggedUser.isAuthenticated){
          //processBatchYear();
          handleSearch();
        }else{
            validateLogin();
        }
       // console.log(batchYear);
      },[loggedUser])
    
      const generateApplicationNo=()=>{
        const current_datetime = new Date();
        var tempUserId="";
        const date_year = (current_datetime.getFullYear()).toString().substring(2,4);
        var date_month = (current_datetime.getMonth() + 1).toString();
        var month_value="";
        date_month.length===1? (month_value=month_value.concat(0,date_month)):(month_value=date_month);
        var date_date = (current_datetime.getDate().toString());
        var date_value="";
        date_date.length===1?(date_value=date_value.toString().concat(0,date_date)):(date_value=date_date);
        var date_hours = (current_datetime.getHours().toString());
        var hours_value="";
        date_hours.length===1?(hours_value=hours_value.toString().concat(0,date_hours)):(hours_value=date_hours);
        var date_minutes = (current_datetime.getMinutes().toString());
        var minutes_value="";
        date_minutes.length===1 ?(minutes_value=minutes_value.toString().concat(0,date_minutes)):(minutes_value=date_minutes);
        var date_seconds = (current_datetime.getSeconds().toString());
        var seconds_value="";
        date_seconds.length===1 ?(seconds_value=seconds_value.toString().concat(0,date_seconds)):(seconds_value=date_seconds);
      
        const accountNo = tempUserId.concat(date_year,month_value, date_value, hours_value, minutes_value, seconds_value);
        //setUserId(accountNo);
        //setMemberInfo({...memberInfo, userId:accountNo});
        return accountNo;      
      
      }
      
      const handleAddMarksheet=()=>{
        setFormType('Add');
        newToggle();
      }

      const handleEditMarksheet=(event, item)=>{
        setFormType('Edit');
        setCourseDetails(item);
        newToggle();
      }

    const newToggle = () => {
        //console.log("clicked");
            setCollapsed(!collapsed);
        }

        const processBatchYear=()=>{
            const current_datetime = new Date();
            const date_year = (current_datetime.getFullYear()).toString().substring(2,4);
            //const lastTenYear=date_year-10;
            var tbatchYear=[];
            for(var i=0; i<10;i++){
              tbatchYear.push(date_year-i);
            }
          //  console.log("batch year : ", tbatchYear);
            setBatchYear(tbatchYear);
            setSelectedBatch(date_year);
            //fetchCurrentYearDetails(date_year);
          }

          const handleSearch=async()=>{
            //event.preventDefault();
            try{
                setLoaded(false);
                const { idToken } = (await fetchAuthSession()).tokens ?? {};
                const searchOption="RegistrationID";
                const searchValue=profileDetails.registrationNo;
                //console.log("searchvlaue : ", searchValue);
              await axios.get(`${config.api.invokeURL}/dashboard/student/marksheet?searchValue=${searchValue}&searchOption=${searchOption}`, {
                headers: {
                  Authorization: idToken,
                  'x-api-key':config.api.key
                }
              }).then(async(response) => {
               //console.log("response : ", response.data);
                if(response.data.length>0)
                {
                    setSearchResults(response.data);
                }
                enqueueSnackbar(response.data.length + " - student's records found!",{variant:'success'}); 
                setLoaded(true);
            }).catch((error)=>{
              enqueueSnackbar("Error Occured - " + error.message,{variant:'warning'}); 
            setLoaded(true);
          })
            }catch(error){
              enqueueSnackbar("Error Occured - " + error.message,{variant:'warning'}); 
            setLoaded(true);
            }
          }

    const reactToPrintContent = React.useCallback(() => {
        return componentRef.current;
      }, [componentRef.current]);
    
      const reactToPrintTrigger = React.useCallback(() => {
        return <Button variant="outline-primary"  size="sm">Print</Button>;
      }, []);
    
      const handleDownload =async(event)=>{
        event.preventDefault();
        csvLinkEl.current.link.click();
      }

      const validateFields=(ops)=>{
        const {courseCode, courseName, courseYear, courseCredit, courseType, totalMarks, 
            courseMarks,coursePoints, marksheetID }=courseDetails;
            const filterResult=searchResults.filter((item)=>item.courseName===courseName);
            const filterResult1=searchResults.filter((item)=>item.courseName===courseName && item.marksheetID!=marksheetID);
            //console.log("test : ", filterResult1);
      var returnValue="passed";
      if(courseMarks<0){
          enqueueSnackbar("Marks cannot be less than zero!",{variant:'warning'}); 
          returnValue="failed";
      }else if(ops==="add" && filterResult.length>0){
          enqueueSnackbar("Marks available for Course Code & Name!",{variant:'warning'}); 
        returnValue="failed";
      }else if(ops==="edit" && filterResult1.length>0){
        enqueueSnackbar("Marks available for Course Code & Name!",{variant:'warning'}); 
      returnValue="failed";
    }else if(courseCode===""){
          enqueueSnackbar("Course Code cannot be empty!",{variant:'warning'}); 
        returnValue="failed";
      }else  if(courseName===""){
          enqueueSnackbar("Course Name cannot be Empty!",{variant:'warning'}); 
      returnValue="failed";
      }else  if(courseYear===""){
          enqueueSnackbar("Course Year cannot be Empty!",{variant:'warning'}); 
      returnValue="failed";
      }else  if(courseCredit===""){
          enqueueSnackbar("Course Credit cannot be Empty!",{variant:'warning'}); 
      returnValue="failed";
      }else  if(courseType===""){
          enqueueSnackbar("Course Type cannot be Empty!",{variant:'warning'}); 
      returnValue="failed";
      }else  if(totalMarks===""){
          enqueueSnackbar("Total Marks cannot be Empty!",{variant:'warning'}); 
      returnValue="failed";
      }else  if(courseMarks===""){
          enqueueSnackbar("Course Marks cannot be Empty!",{variant:'warning'}); 
      returnValue="failed";
      }else  if(coursePoints===""){
          enqueueSnackbar("Course Points cannot be Empty!",{variant:'warning'}); 
      returnValue="failed";
      }else  {
        returnValue="passed";
      }
      return returnValue;
      }

      const handleSubmit=async(event)=>{
        event.preventDefault();
        try{
        const validationResult=validateFields("add");
        if(validationResult==="passed"){
          setLoaded(false);
          const {courseCode, courseName, courseYear, courseCredit, courseType, totalMarks, 
          courseMarks,coursePoints }=courseDetails;
          const clientID = "VJCollege";
          const applicationno= await generateApplicationNo();
          const applicationDate=await generateDate();
          const { idToken } = (await fetchAuthSession()).tokens ?? {};
          const params={
            clientID:clientID, courseCode: courseCode, courseName: courseName, courseYear:courseYear,
            courseCredit:courseCredit,courseType:courseType, totalMarks:totalMarks, courseMarks:courseMarks,
            coursePoints:coursePoints, marksheetID:applicationno, registrationNo:profileDetails.registrationNo,
            updatedBy:loggedUser.name, updatedOn:applicationDate};
            //console.log(params);
          await axios.post(`${config.api.invokeURL}/dashboard/student/marksheet`, params,{
            headers: {
              Authorization: idToken,
              'x-api-key':config.api.key
            }})
            .then(async(response)=>{
              newToggle();
              handleSearch();
              enqueueSnackbar("Marks Successfully Added",{variant:'success'}); 
              setLoaded(true);
            }).catch((error)=>{
              enqueueSnackbar("Error Occured - " + error.message,{variant:'warning'}); 
              setLoaded(true);
            })
          }
          }catch(error){
           //console.log("error : ", error);
           enqueueSnackbar("Error Occured - " + error.message,{variant:'warning'}); 
        setLoaded(true);
        }
      }

      const handleEdit=async(event)=>{
        event.preventDefault();
        try{
        const validationResult=validateFields("edit");
        if(validationResult==="passed"){
          setLoaded(false);
          const todayDate=await generateDate();
          const clientID = "VJCollege";
          //console.log("logged User :", loggedUser);
          //const clientID=loggedUser.familyName;
          //const session = await Auth.currentSession();
          //const access_token=session.idToken.jwtToken;
          const { idToken } = (await fetchAuthSession()).tokens ?? {};
          const {courseCode, courseName, courseYear, courseCredit, courseType, totalMarks, 
            courseMarks,coursePoints, marksheetID, courseResult }=courseDetails;
            //console.log("Course Details : ", courseDetails);

            const params={
              clientID:clientID, 
              courseCode: courseCode, 
              courseName: courseName, 
              courseYear:courseYear,
              courseCredit:courseCredit.toString(),
              courseType:courseType, 
              totalMarks:totalMarks.toString(), 
              courseMarks:courseMarks,
              coursePoints:coursePoints.toString(), 
              marksheetID:marksheetID, 
              registrationNo:profileDetails.registrationNo,
              courseResult:courseResult,
              updatedBy:loggedUser.name, 
              updatedOn:todayDate};
             // console.log(params);
        //console.log("Params : ", params);
          //await axios.patch(`${config.api.invokeURL}/dashboard/officeform`,params, {  
          await axios.patch(`${config.api.invokeURL}/dashboard/student/marksheet`, params,{
            headers: {
              Authorization: idToken,
              'x-api-key':config.api.key
            }})
            .then(async(response)=>{
             // console.log("response : ", response); 
             /* if(newImage){
              await postOnS3(userID);
              }*/
              enqueueSnackbar("Marksheet Updated successfully!",{variant:'success'});
              newToggle();
            window.scrollTo(0,0);
            setLoaded(true);
            }).catch((error)=>{
              enqueueSnackbar("Error Occured - " + error.message,{variant:'warning'});
              window.scrollTo(0,0);
              setLoaded(true);
            })
        }
          }catch(error){
            enqueueSnackbar("Error Occured - " + error.message,{variant:'warning'});
            window.scrollTo(0,0);
            setLoaded(true);
        }
      }

      const handleDelete=async(event, item)=>{
        event.preventDefault();
        try{
        //const clientID = "VJCollege";
        setLoaded(false);
        const { idToken } = (await fetchAuthSession()).tokens ?? {};
        const { marksheetID }=item;
          await axios.delete(`${config.api.invokeURL}/dashboard/student/marksheet?marksheetid=${marksheetID}`, {
            headers: {
              Authorization: idToken,
              'x-api-key':config.api.key
            }})
            .then(async(response)=>{
              const temp=searchResults.filter(item=>item.marksheetID!==marksheetID)
              //console.log("Temp : ", temp);
              setSearchResults(temp);
              enqueueSnackbar("Marksheet Deleted successfully!",{variant:'success'});
             // newToggle();
            window.scrollTo(0,0);
            setLoaded(true);
            }).catch((error)=>{
              //console.log("Error :", error);
              enqueueSnackbar("Error Occured - " + error.message,{variant:'warning'});
              window.scrollTo(0,0);
              setLoaded(true);
            })
          }catch(error){
            //console.log("Error1 :", error);
            enqueueSnackbar("Error Occured - " + error.message,{variant:'warning'});
            window.scrollTo(0,0);
            setLoaded(true);
        }
      }
      

  return (
    <div>
    <Helmet>
      <title>Mark Sheet - Admin Office - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies</title>
      <meta name="description" content="Mark Sheet - Admin Office - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies" />
      <meta name="keywords" content="Mark Sheet - Admin Office - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies" /> 
      <link rel="canonical" href="/student/marksheet" />
    </Helmet>
    <Preloader show={loaded ? false : true} />
    <Breadcrumb>
    <Breadcrumb.Item>
      <Link to="/">
      <i className="fa fa-home" aria-hidden="true"></i>
      </Link>
    </Breadcrumb.Item>
    <Breadcrumb.Item >
      Students
    </Breadcrumb.Item>
    <Breadcrumb.Item >
      Mark Sheet
    </Breadcrumb.Item>
    </Breadcrumb>
    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
      <div className="d-block mb-4 mb-md-0">
        <h3>Mark Sheet</h3>
        </div>
      <div className="btn-toolbar mb-2 mb-md-0"> 
        <ButtonGroup>
        <ReactToPrint
      content={reactToPrintContent}
      documentTitle="student"
      trigger={reactToPrintTrigger}
    />
        <Button variant="outline-primary" size="sm" onClick={(event)=>{handleDownload(event)}}>Download</Button>
        <CSVLink
            headers={downloadHeaders}
            filename="download.csv"
            data={searchResults}
            ref={csvLinkEl}
          />
        </ButtonGroup>
      </div>
    </div>
   
    <Modal onHide={newToggle} show={!collapsed} style={{top:'10%'}}>
            <Modal.Header toggle={newToggle}>
            <Modal.Title>Mark Sheet - {formType}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <MarkSheetForm  formType={formType} profileDetails={profileDetails} courseDetails={courseDetails} setCourseDetails={setCourseDetails}/>
            </Modal.Body>
            <Modal.Footer> 
      {formType==="Add"?<Button
        color="primary"
        onClick={handleSubmit}
      >
        Add       
        </Button>:formType==="Edit"?<Button
        color="primary"
        onClick={handleEdit}
      >
        Update 
       </Button>:null}
  {' '}
      <Button onClick={newToggle}>
        Close
      </Button>
    </Modal.Footer>
    </Modal>  
    <div>
   {/*} <div className="table-settings mb-4">
      <Row className="justify-content-start align-items-center">
      <Col md={3} className="mb-3">
            <Form.Group id="searchstud">               
              <Form.Select defaultValue="ApplicationID" value={searchTopic} 
              size="lg" style={{fontSize:16, borderRadius:10, padding:8, width:"100%"}}
              onChange={(event)=>{setSearchTopic(event.target.value)}}>
              <option value="Batch">Batch</option>
              <option value="RegistrationID">Registration No</option>
              <option value="ApplicationID">Application No</option>
                <option value="CourseStatus">Course Status</option>
                <option value="FeeStatus">Fee Status</option>
              </Form.Select>
            </Form.Group>
          </Col>
        <Col  className="mb-3" md={6}>
          <InputGroup>
            <InputGroup.Text>
              <FontAwesomeIcon icon={faSearch} />
    </InputGroup.Text>
            {searchTopic==="Batch"?           
            <Form.Select value={searchValue} 
            size="lg" style={{fontSize:16, borderRadius:10, padding:8, width:"100%"}}
                onChange={(event)=>{setSearchValue(event.target.value)}}>
                {batchYear.map(item => {
                  return (
                  <option key={item} value={item}>{item}</option>
                  );
                })}
          </Form.Select>:
          <Form.Control type="text" placeholder="Search" value={searchValue} onChange={(event)=>{setSearchValue(event.target.value)}}/>}

          </InputGroup>
        </Col>
        <Col  className="mb-3" md={2}>
        <Button variant="primary" type="submit" onClick={(event)=>{handleSearch(event)}}>Submit</Button>
        </Col>
      </Row>
    </div>*/}
    <Card>
    <Row>
            <Col md={12} className="mb-3">
              <Form.Group id="firstName">
                <Form.Label>Degree Program</Form.Label>
                <Form.Select size="lg" style={{fontSize:16, borderRadius:10, padding:8, width:"100%"}}
                    value={profileDetails.courseCategory} disabled
                    //onChange={(event)=>{setProfileDetails({...profileDetails, courseCategory:event.target.value})}}
                    >
                  <option value="Bachealor of Theology">Bachealor of Theology</option>
                  <option value="Master of Theology">Master of Theology</option>
                  <option value="Licentiate in Sacred Theology">Licentiate in Sacred Theology</option>
                  <option value="Doctorate of Theology">Doctorate of Theology</option>
                </Form.Select>
              </Form.Group>
            </Col>
          </Row> 
          <Row>
            <Col md={6} className="mb-3">
              <Form.Group id="firstName">
                <Form.Label>Student Name</Form.Label>
                <Form.Control required type="text" placeholder="Enter your name...." value={profileDetails.applicantName}
                  disabled
                  //onChange={(event)=>{setProfileDetails({...profileDetails, applicantName:(event.target.value).toUpperCase()})}}  
               />
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group id="middleName">
                <Form.Label>Registraton No</Form.Label>
                <Form.Control required type="text" value={profileDetails.registrationNo}
                  placeholder='Enter Father name....' disabled
                  //onChange={(event)=>{setProfileDetails({...profileDetails, fatherName:(event.target.value).toUpperCase()})}}
                />
              </Form.Group>
            </Col>
          </Row>
    </Card>
    <Dropdown>
        <Dropdown.Toggle as={Button} onClick={(event)=>{handleAddMarksheet(event)}} className="buttonStyle me-2">
        <i className="fa fa-plus me-2" aria-hidden="true"></i>
        <span>New</span>
        </Dropdown.Toggle>
    </Dropdown>
    <Table ref={componentRef} hover responsive>
        <thead className='theadStyle'>
            <tr>
                <th>#</th>
                <th>Code</th>
                <th>Name</th>
                <th>Credit</th>
                <th>Marks</th>
                <th>Action</th>
            </tr>
        </thead>
        <tbody className='tbodyStyle'>
        {searchResults.map((item, index)=>
        <tr key={index} className='trStyle'>
        <th scope="row">{index+1}</th>
        <td>{item.courseCode}</td>
        <td>{item.courseName}</td>
        <td>{item.courseCredit}</td>
        <td>{item.courseMarks}</td>
        <td>
        <Dropdown as={ButtonGroup}>
        <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
            <span className="icon icon-sm">
            <i className="fa fa-ellipsis-h icon-dark" aria-hidden="true"></i>
            </span>
        </Dropdown.Toggle>
        <Dropdown.Menu>
        <Dropdown.Item  onClick={(event)=>{handleEditMarksheet(event, item)}}>
        <i className="fa fa-pencil me-2" aria-hidden="true">Edit Marks</i>
        </Dropdown.Item>
        <Dropdown.Item  onClick={(event)=>{handleDelete(event, item)}}>
        <i className="fa fa-trash-o me-2" aria-hidden="true"> Delete Marks</i>
        </Dropdown.Item>
        {/*<Dropdown.Item  onClick={(event)=>{handleAssignCourse(event, item)}}>
            <FontAwesomeIcon icon={faEdit} className="me-2" /> Update Course
        </Dropdown.Item>
        <Dropdown.Item  onClick={(event)=>{viewScoresToggle(item)}}>
            <FontAwesomeIcon icon={faEdit} className="me-2" /> View Marks
        </Dropdown.Item>
        <Dropdown.Item  onClick={(event)=>{viewJourneyToggle(item)}}>
            <FontAwesomeIcon icon={faEdit} className="me-2" /> View Journey
        </Dropdown.Item>*/}
        </Dropdown.Menu>
    </Dropdown>
        </td>
        </tr>)}
        </tbody>
    </Table>
  </div>
</div>
)
}
