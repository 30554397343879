import React, { useEffect, useState } from "react";
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { faBoxOpen, faCartArrowDown, faChartPie, faChevronDown, faClipboard, faCommentDots, faFileAlt, faPlus, faRocket, faStore } from '@fortawesome/free-solid-svg-icons';
//import { Col, Row, Button, Dropdown } from '@themesberg/react-bootstrap';
//import { ChoosePhotoWidget, ProfileCardWidget } from "../components/Widgets";
//import { GeneralInfoForm } from "../components/Forms";
import { Col, Row, Card, Form } from 'react-bootstrap';
//import AddImage from "../../components/manageimage/AddImge";

//import Profile3 from "../assets/img/team/profile-picture-3.jpg";


export default function InfoDeskForm(props) {
    const [isDisabled, setIsDisabled]=useState(true);
    const {infoDesk, setInfoDesk, flashNews, setFlashNews, formType, faithMsg, setFaithMsg, setImageFile, imageFile, profileImage } = props;
    const inputRef = React.useRef();

    useEffect(()=>{
        if(formType==="View"){
        setIsDisabled(true);
        }else{
            setIsDisabled(false);
        }
    },[])

    const handleFileSelection = (event)=>{
        event.preventDefault();
        if (event.target.files && event.target.files.length > 0) {
          setImageFile(event.target.files[0]);
          //var tprofile1 = document.getElementById("img1");
          //tprofile1.src=URL.createObjectURL(event.target.files[0]);
      }
  }

    return (
    <>
      <Card border="light" className="bg-white shadow-sm">
      <Card.Body>
        <Form>
        <Row>
            <Col className="mb-3">
              <Form.Group id="category">
                <Form.Label>Category</Form.Label>
                <Form.Select value={infoDesk.msgCategory} 
                onChange={(event)=>{setInfoDesk({...infoDesk,
                    msgCategory:event.target.value})}}
                    disabled={isDisabled} >
                  <option value="Flash News">Flash News</option>
                  <option value="Theological Insights">Theological Insights</option>
                  </Form.Select>
              </Form.Group>
            </Col>
          </Row>
          {infoDesk.msgCategory==="Flash News" ? <div>

          <Row className="align-items-center">
            <Col md={6} className="mb-3">
              <Form.Group id="startDate">
                <Form.Label>Start Date</Form.Label>
                <Form.Control type="date" value={infoDesk.startDate} 
                onChange={(event)=>{setInfoDesk({...infoDesk,
                startDate:event.target.value})}} 
                disabled={isDisabled}/>
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group id="endDate">
                <Form.Label>End Date</Form.Label>
                <Form.Control type="date" value={infoDesk.endDate} 
                onChange={(event)=>{setInfoDesk({...infoDesk,
                    endDate:event.target.value})}}
                    disabled={isDisabled} />
              </Form.Group>
            </Col>
          </Row>
          {infoDesk.msgCategory==="Flash News"?<div>
            <Row className="align-items-center">
            <Col md={6} className="mb-3">
              <Form.Group id="startDate">
                <Form.Label>Background Color</Form.Label>
                <Form.Control
                  type="color"
                  id="exampleColorInput"
                  defaultValue="#563d7c"
                  title="Choose your color"
                  value={flashNews.bcolor} 
                onChange={(event)=>{setFlashNews({...flashNews,
                bcolor:event.target.value})}} 
                disabled={isDisabled}
                />
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group id="endDate">
                <Form.Label>Text Color</Form.Label>
                <Form.Control
                  type="color"
                  id="exampleColorInput"
                  defaultValue="#FFFFFF"
                  title="Choose your color"
                  value={flashNews.fcolor} 
                  onChange={(event)=>{setFlashNews({...flashNews,
                  fcolor:event.target.value})}}
                  disabled={isDisabled} 
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
              <Form.Group id="flashNews">
                <Form.Label>Flash News</Form.Label>
                <Form.Control required as="textarea" rows="3" placeholder="Enter the Flash News" 
                value={flashNews.message} onChange={(event)=>{setFlashNews({...flashNews, message:event.target.value})}} 
                disabled={isDisabled} maxLength={5000}/>
              </Form.Group>
          </Row></div>:null}
          </div>:null}
          {/*infoDesk.category==="Scroll Message"?<div>
          <Row>
            <AddImage formType={formType} setImageFile={setImageFile} profileImage={profileImage}/>
          </Row>

            <Row>
              <Form.Group id="title">
                <Form.Label>Title</Form.Label>
                <Form.Control required type="text" placeholder="Title" 
                value={scrollMsg.title} onChange={(event)=>{setScrollMsg({...scrollMsg,
                    title:event.target.value})}} 
                    disabled={isDisabled}
                />
              </Form.Group>
              </Row>
              <Row>
              <Form.Group id="msg">
                <Form.Label>Message</Form.Label>
                <Form.Control required type="textarea" placeholder="Enter the Message" rows="3"
                value={scrollMsg.message} onChange={(event)=>{setScrollMsg({...scrollMsg,
                    message:event.target.value})}} 
                    disabled={isDisabled}/>
              </Form.Group>            
          </Row>
          <Row>
              <Form.Group id="emal">
                <Form.Label>Email</Form.Label>
                <Form.Control required type="email" placeholder="name@company.com" 
                value={scrollMsg.email} onChange={(event)=>{setScrollMsg({...scrollMsg,
                    email:event.target.value})}}
                    disabled={isDisabled} />
              </Form.Group>
            </Row>
            <Row>
              <Form.Group id="Link">
                <Form.Label>Link</Form.Label>
                <Form.Control required type="text" placeholder="https://example.com" 
                value={scrollMsg.link} onChange={(event)=>{setScrollMsg({...scrollMsg,
                    link:event.target.value})}} 
                    disabled={isDisabled}/>
              </Form.Group>
          </Row></div>:null*/}
          
          {infoDesk.msgCategory==="Theological Insights"?<div>
           <div m="50px">
        <Form.Group as={Row} className="mb-3" controlId="formPlaintextImage">
          <Form.Label column sm="4">
            Select Image
          </Form.Label>
          <Col sm="8">
          <Form.Control type="file"
            controlId="formFileImage"  
            accept="image/*" ref={inputRef}
            onChange={handleFileSelection} />
          </Col>
        </Form.Group>
      </div>
          <Row>
              <Form.Group id="msgText">
                <Form.Label>Message Details</Form.Label>
                <Form.Control required as="textarea" placeholder="Message"  rows={5} 
                value={faithMsg.message} onChange={(event)=>{setFaithMsg({...faithMsg,
                    message:event.target.value})}} maxLength={5000}
                    disabled={isDisabled}/>
              </Form.Group>
          </Row></div>:null}
          
        </Form>
      </Card.Body>
    </Card>
    </>
  );
};
