import React, {useState} from 'react';
import {Button, Form, Row, Col} from 'react-bootstrap';
import { Link, useNavigate } from "react-router-dom";
import { resetPassword, confirmResetPassword } from 'aws-amplify/auth';
import { useSnackbar } from 'notistack';
import logo from '../../assets/images/logo/COLLEGELOGO.jpeg';
import Preloader from '../../components/Preloader/Preloader';

export default function ForgotPassword() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [username, setUsername]= useState('');
  const [confirmationCode, setConfirmationCode]= useState('');
  const [newPassword, setNewPassword] = useState('');
  const [nextPage, setNextPage]=useState(false);  
  const [loaded, setLoaded] = useState(true);

  const handleSendAuthEmail=async(event)=>{
    event.preventDefault();
    try {
      if(username===""){
        enqueueSnackbar("Enter your registered eamil address!",{variant:'info'});
      }else{
      setLoaded(false);
      const output = await resetPassword({ username });
      //console.log("Output : ", output);
      enqueueSnackbar("Password Reset initiated!",{variant:'info'});
      handleResetPasswordNextSteps(output);
      setLoaded(true);
      }
    } catch (error) {
      enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});
      setLoaded(true);
    }  
   // setNextPage(true);
  }

  function handleResetPasswordNextSteps(output) {
    setLoaded(false);
    const { nextStep } = output;
    switch (nextStep.resetPasswordStep) {
      case 'CONFIRM_RESET_PASSWORD_WITH_CODE':
        const codeDeliveryDetails = nextStep.codeDeliveryDetails;
        enqueueSnackbar(`Confirmation code was sent to ${codeDeliveryDetails.deliveryMedium}`,{variant:'info'});
        setNextPage(true);
        setLoaded(true);
        // Collect the confirmation code from the user and pass to confirmResetPassword.
        break;
      case 'DONE':
        enqueueSnackbar('Password Reset Successful!',{variant:'info'});
        setLoaded(true);
        break;
    }
  
  }

  const handleConfirmResetPassword =async(event)=>{
    event.preventDefault();
    try {
      if(confirmationCode===""){
        enqueueSnackbar("Enter your code sent to your registered eamil address!",{variant:'info'});
      }else if(newPassword===""){
        enqueueSnackbar("Enter your new Password!",{variant:'info'});
      }else if(username===""){
        enqueueSnackbar("Enter your registered eamil address!",{variant:'info'});
      }else{
      setLoaded(false);
      await confirmResetPassword({ username, confirmationCode, newPassword })
      .then((res)=>{
        enqueueSnackbar("Your Password has been changed successfully! Login back with your New Password!",{variant:'info'});
      setLoaded(true);
      navigate("/auth/login");
      })
      .catch((error)=>{
        setLoaded(true);
        enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});
      })
    }
    } catch (error) {
      setLoaded(true);
      enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});
    }  
  }

  return (
    <>
    <Preloader show={loaded ? false : true} />
    <div style={{marginTop:'5%', marginBottom:'auto'}}>
      <div className="col d-flex justify-content-center text-center">
        <div className="card">
          <div className="card__body">
        <h2>Vidyajyoti College of Theology</h2>
        <h2>Delhi</h2>
        <img
            src={logo}
            alt="logo"
            width="150"
            className="shadow-light rounded-circle"
        />
          <h4>Forgot your password?</h4>
          <p className="mb-4">Don't fret! Just type in your email and we will send you a code to reset your password!</p>
          <div component="form" noValidate sx={{ mt: 1 }}>
            <Form>
            <Form.Group  as={Row} className="mb-3" controlId="exampleForm.ControlInput1">
        <Form.Label column sm="4">Email address</Form.Label>
        <Col sm="8">
        <Form.Control type="email" placeholder="name@example.com"
          autoFocus
          onChange={event=>setUsername(event.target.value)}
          value={username}
          disabled={nextPage} />
         </Col>
         </Form.Group>
            </Form>
            {nextPage ?<div>
                  <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                  <Form.Label column sm="4">
                    Enter Code
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control type="text" placeholder="Password"               
              onChange={event=>setConfirmationCode(event.target.value)}
              value={confirmationCode}/>
                  </Col>
                </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
            <Form.Label column sm="2">
              New Password
            </Form.Label>
            <Col sm="10">
              <Form.Control type="text" placeholder="Password"               
              onChange={event=>setNewPassword(event.target.value)}
              value={newPassword}/>
            </Col>
          </Form.Group></div>:null}
            {/*<FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
        />*/}
            {!nextPage? <Button
              type="submit"
              fullWidth
              variant="primary"
              onClick={event=>handleSendAuthEmail(event)}>
              Generate Code
            </Button>:<Button
              type="submit"
              fullWidth
              variant="primary"
              sx={{ mt: 3, mb: 2 }}
              onClick={event=>handleConfirmResetPassword(event)}>
                Reset Password
            </Button>}
            <div className='container'>
              <div item xs>
                <Link to="/login" variant="body2">
                  Sign In?
                </Link>
              </div>
              {/*<Grid item>
                <Link href="#" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>*/}
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>
    </>
  )
}
