import React, { useState, useEffect } from 'react'
import axios from 'axios';
//import { Auth } from "aws-amplify";
import AddImage from './AddImage';
import logo from '../../assets/images/logo/COLLEGELOGO.jpeg';
import { Button, Card, Modal, CardGroup, Container, CardFooter} from "react-bootstrap";
import { useSnackbar } from 'notistack';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { faTrash} from '@fortawesome/free-solid-svg-icons';
import Preloader from '../../components/Preloader/Preloader';

const config = require('../../config.json');


export default function GalleryImageLoader(props) {
    const [loaded, setLoaded] = useState(true);
    const {keyName, projectName, orginalfetchGalleryImages, galleryImages, setGalleryImages, selectedProject}=props;
    //const [galleryImages, setGalleryImages] = useState('');
    const [addImage, setAddImage] = useState(false);
    const [imageFile, setImageFile] = useState([]);
    const [profileImage, setProfileImage] = useState(logo);
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        //fetchGalleryImages();  
       // console.log("Props: ", props);
        return () => {
          orginalfetchGalleryImages();
        }
    }, []);       

    const handleDeleteImage = async(event, imageName)=>{
        event.preventDefault();
        setLoaded(false);
       // const imageid=imageName.substring(12,imageName.length);
       try{
           if(galleryImages.length<2){
            enqueueSnackbar("Delete not allowed! Only one image left in Gallery",{variant : 'info'});
           }else{
            //const session = await Auth.currentSession();
            //const access_token=session.idToken.jwtToken;
            //console.log("image : ", imageName  );
        await axios.get(`${config.api.invokeURL}/gallery/remove?imageName=${imageName}`,{
            headers:{
                //Authorization: access_token,
                'x-api-key': config.api.key
            }
        }).then((response)=>{
            //console.log(response);
            //setGalleryImages(response.data);
            //setDispMsg("Deleted Successfully");
            enqueueSnackbar("Deleted Successfully",{variant : 'success'});
            fetchGalleryImages();
        }).catch((error)=>{
           // setDispMsg(error.message);
           enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});
            setLoaded(true);
        })
    }
    }catch(error){
      enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});
      setLoaded(true);
    }
    setLoaded(true);
    }

    const handleOpenImageAdd = (event) => {
        event.preventDefault();
        setAddImage(true);
    }
    const handleCloseImageAdd = (event) => {
        event.preventDefault();
        setAddImage(false);
    }
  
    const fetchGalleryImages =async()=>{
        try{
            setLoaded(false);
            //const session = await Auth.currentSession();
            //const access_token=session.idToken.jwtToken;
          //const fileName='images/gallery/';
         // const bucketName="images.stjosephhospitaldindigul.in";
      //await axios.get(`${config.api.invokeUrl}/images/gallery?fileName=${keyName}&bucketName=${bucketName}`,{
        //const fileName='images/doctor/';
        //const tfoldername=item.folderName.substring(0, item.folderName.length-1);
        const fileName=keyName;
        const bucketName="images.vidyajyoticollege.in";
  
      await axios.get(`${config.api.invokeURL}/gallery/slider?fileName=${fileName}&bucketName=${bucketName}`,{
  
            headers: {
              //Authorization: access_token,
              'x-api-key': config.api.key
            }}, 
        ).then((response)=>{
            const finalList=[];
            //console.log("res:", response);
            if(response.data.length>0){
              for (const i in response.data){
                const tValue=response.data[i];
              //  console.log("tvalue:", tValue);
                const tPathName=tValue.split("/");
                //console.log("tpathname:", tPathName);
                const tBreakUp=tPathName[2];
                //console.log("tbreakup:", tBreakUp);
                const tempValue={};
                tempValue.fullPathName=response.data[i];
                tempValue.fileName=tBreakUp;
                finalList.push(tempValue);
              }
            }
           // console.log(response);
            setGalleryImages(finalList);
            
            enqueueSnackbar(response.data.length + " - Images are found in your " + projectName,{variant : 'success'});
            setLoaded(true);
            window.scrollTo(0,0); 
        }).catch((error)=>{
          enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});
          setLoaded(true);
        })
    }catch(error){
      enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});
        setLoaded(true);
    }
    setLoaded(true);
    }

    const postOnS3 = async(event)=>{
    event.preventDefault();
    try{ 
        setLoaded(false);
           const fileName=projectName+"_"+new Date().getTime();
            const newFileName=fileName+".jpeg";
            const newKeyName=selectedProject.projectName+"$"+selectedProject.projectDate;
            const tKeyName="/images/gallery/"+newKeyName;
            const bucketName=config.s3.bucketName+tKeyName;
          var file = imageFile;
          var blob = file.slice(0, file.size, 'image/jpeg'); 
          const newFile = new File([blob], newFileName, {type: 'image/jpeg'});
          await axios.get(`${config.api.invokeURL}/presigned?fileName=${newFileName}&bucketName=${bucketName}`,{
              headers:{
           //       Authorization: access_token,
                  'x-api-key': config.api.key
              }
          }).then(async(response)=>{
              //console.log("Response : ", response);
              const res=await fetch(response.data.uploadURL, { method: 'PUT',
              headers:{
                  "Content-Type":"image/jpeg"
                },body: newFile});
            }).catch((error)=>{
              enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});
                setLoaded(true);
          })
       // }
        await fetchGalleryImages();
        handleCloseImageAdd(event);
    }catch(error){
      enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});
    setLoaded(true);
    }
    setLoaded(true);
    }

    return (
    <>
    <Preloader show={loaded ? false : true} />
    <div>
        <div className="container" maxWidth="md" style={{padding:"20px"}}>
              <Button type="submit" className='buttonStyle'
              onClick={event=>handleOpenImageAdd(event)}>
                New
              </Button>
        </div>
        <Modal show={addImage} onHide={handleCloseImageAdd}  size="xxl"
      centered fullscreen="xxl">
        <Modal.Header>
        <Modal.Title>Add Gallery Images</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <AddImage preloaded={loaded} imageFile={imageFile} setImageFile={setImageFile} profileImage={profileImage} />
      </Modal.Body>
        <Modal.Footer>
        <div style={{display:"flex", justifyContent:"end"}}>
          <Button type="submit" className='buttonStyle' onClick={event=>postOnS3(event) }>
            Save
          </Button>
          <Button className='buttonStyle' onClick={event=>handleCloseImageAdd(event)}>
            Cancel
          </Button>
        </div>
        </Modal.Footer>
      </Modal>
      <Container>
        <div className='row'> 
        <CardGroup>
            {galleryImages.map((item, index) => (
                <div className="col  grid-container" key={index}> 
                <Card key={index} border="primary">
                  <Card.Img variant="top"
                    src={`${config.s3.invokeURL}${item.fullPathName}`}
                    style={{ width: '100%' }} 
                  />
                <CardFooter style={{ paddingTop:'10px', textAlign:'center' }} >
                <i className="fa fa-trash-o" aria-hidden="true" onClick={event=>handleDeleteImage(event, item.fullPathName)}></i>
                  </CardFooter>
                  {/*<CardActions sx={{ alignItems:'center' }} onClick={event=>handleDeleteImage(event, item.fullPathName)}>
                  <IconButton>
                    <DeleteIcon />
                  </IconButton>
                </CardActions>*/}
                </Card>
                </div>
            ))}
          </CardGroup>
          </div>
          </Container>
    </div>
    </>
    )
}
