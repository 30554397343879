import React, {useEffect, useState, useContext} from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import ThemeAction from './redux/actions/ThemeAction'
import Topnav from './components/topnav/TopNav';
import LoginPage from './pages/auth/LoginPage';
import ForgotPassword from './pages/auth/ForgotPassword';
import {ProductContext} from './ProductProvider';
import LandingPage from './pages/LandingPage';
import SliderViewer from './pages/website/SliderViewer';
import ChangePassword from './pages/auth/ChangePassword';
import GalleryViewer from './pages/website/GalleryViewer';
import ListAdmins from './pages/admin/ListAdmins';
import Sidebar from './components/prosidebar/Sidebar';
import VJToday from './pages/publications/VJToday';
import LogoutPage from './pages/auth/LogoutPage';
//import { FaBars } from 'react-icons/fa';

import './App.css';

function App() {
  const themeReducer = useSelector(state => state.ThemeReducer)
  const navigate = useNavigate();
  const dispatch = useDispatch()
  //const [isSidebar, setIsSidebar] = useState(true);
  const { loggedUser} = useContext(ProductContext);
  const [collapsed, setCollapsed] = useState(false);
  const [image, setImage] = useState(false);
  const [toggled, setToggled] = useState(false);

  const handleCollapsedChange = () => {
    //console.log("clicked")
    setCollapsed(!collapsed);
  };

  const handleImageChange = (checked) => {
    setImage(checked);
  };

  const handleToggleSidebar = (value) => {
    setToggled(value);
  };

  useEffect(() => {
    const themeClass = localStorage.getItem('themeMode', 'theme-mode-light')
    const colorClass = localStorage.getItem('colorMode', 'theme-mode-light')
    dispatch(ThemeAction.setMode(themeClass))
    dispatch(ThemeAction.setColor(colorClass))
  }, [dispatch, loggedUser])

  return (
    <div className={`layout ${themeReducer.mode} ${themeReducer.color}`}>
      {loggedUser.isAuthenticated ?
      <div className="app">
        <Sidebar image={image}
        collapsed={collapsed}
        toggled={toggled}
        handleToggleSidebar={handleToggleSidebar}
        handleCollapsedChange={handleCollapsedChange} />
        <main className="layout__content">
        <Topnav />
        <div className="layout__content-main">
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/slider-viewer" element={<SliderViewer />} />
            <Route path="/gallery-viewer" element={<GalleryViewer />} />
            <Route path="/publications/vjtoday" element={<VJToday />} />
            <Route path="/change-password" element={<ChangePassword />} />
            <Route path="/list-admins" element={<ListAdmins />} />
            <Route path="/logout" element={<LogoutPage />} />
          </Routes>
          </div>
        </main>
        </div>:<div className="layout__content-main">
        <Routes>
          <Route path="/" element={<LoginPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
        </Routes>
        </div>}
                     {/*} <div className={`layout ${themeReducer.mode} ${themeReducer.color}`}>
                   <ProSidebar />
                    <div className="layout__content">
                        <Topnav/>
                        <div className="layout__content-main">
                            <NavRoutes/>
                        </div>
                    </div>
  </div>*/}
    </div>
  );
}

export default App;
