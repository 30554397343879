import React, { useEffect, useState, useContext } from "react";
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { faHome, faEllipsisH, faPlusCircle, faExchange } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Form, Button, ButtonGroup, Breadcrumb, Table, Dropdown, Modal } from 'react-bootstrap';
//import {Input } from 'reactstrap';
import axios from "axios";
import { useSnackbar } from 'notistack';
import { Link } from "react-router-dom";
import { fetchAuthSession } from 'aws-amplify/auth';
import { ProductContext } from '../../ProductProvider';
//import { courseMaterialData } from '../../ProData';
import Preloader from '../../components/Preloader/Preloader';
//import ProgressBarStatus from "../../components/ProgresssBarStatus";
import EmailUpdateModal from "../students/EmailUpdateModal";
import {Helmet} from 'react-helmet-async';

//import { TransactionsTable } from "../../components/Tables";
const config = require('../../config.json');
const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
const phoneRegex=/^\+[1-9]{1}[0-9]{3,14}$/;
const dobRegex=/([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/;

export default function StaffAccounts() {
  const { loggedUser, validateLogin } = useContext(ProductContext);
  const { enqueueSnackbar } = useSnackbar();
  const [searchTopic, setSearchTopic] = useState('UserID');
  const [searchValue, setSearchValue] = useState('');

  const [batchYear, setBatchYear] = useState([]);
  const [selectedBatch, setSelectedBatch]=useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [loaded, setLoaded]=useState(true);
  const componentRef = React.useRef(null);
  const [updateEmailCollapsed, setUpdateEmailCollapsed]=useState(false);
  const [selectedEmail, setSelectedEmail]=useState('');
  const [selectedRegistrationNo, setSelectedRegistrationNo]=useState('');
  const [registeredResults, setRegisteredResults]=useState([]);
  const [missingResults, setMissingResults]=useState([]);
  
  useEffect(()=>{
    if(loggedUser.isAuthenticated){
     // processBatchYear();
    }else{
    validateLogin();
    }
   // console.log(batchYear);
  },[loggedUser])

  const processBatchYear=()=>{
    const current_datetime = new Date();
    const date_year = (current_datetime.getFullYear()).toString().substring(2,4);
    //const lastTenYear=date_year-10;
    var tbatchYear=[];
    for(var i=0; i<10;i++){
      tbatchYear.push(date_year-i);
    }
  //  console.log("batch year : ", tbatchYear);
    setBatchYear(tbatchYear);
    setSelectedBatch(date_year);
    //fetchCurrentYearDetails(date_year);
  }

  const fetchCurrentYearDetails=async()=>{
   // event.preventDefault();
    try{
      if(searchValue===""){
        enqueueSnackbar("Search Value is empty Enter the search input and then hit Search button!",{variant:'warning'}); 
      }else{
        setLoaded(false);
        const { idToken } = (await fetchAuthSession()).tokens ?? {};
        await axios.get(`${config.api.invokeURL}/dashboard/officeform/accounts/listusers?searchTopic=${searchTopic}&searchValue=${searchValue}`, {  
        headers: {
          Authorization: idToken,
          'x-api-key':config.api.key
        }
      }).then(async(response) => {
        //console.log("Response : ", response);
        setSearchResults(response.data[0]);

       /* if(response.data.length>0)
        {

            const tempResponse=response.data[0];
            var tempOutput=[];
            if(tempResponse.length>0){
            tempOutput=tempResponse.filter((item)=>item.Attributes[0].Value==="Student"||item.Attributes[0].Value==="Super Student");
          }
            setSearchResults(tempOutput);
            setRegisteredResults(response.data[1]);
            processResults(response.data[0], response.data[1]);*/
            enqueueSnackbar("Login Details loaded Successfully!",{variant:'success'}); 
            setLoaded(true);
        
    }).catch((error)=>{
      enqueueSnackbar("Error Occured - " + error.message,{variant:'warning'}); 
    setLoaded(true);
  })
}
    }catch(error){
      enqueueSnackbar("Error Occured - " + error.message,{variant:'warning'}); 
    setLoaded(true);
    }
  }

  const processResults=(taccounts,tregister)=>{
   // console.log("Register : ", tregister);
   // console.log("Users : ", taccounts);
    var ttaccounts=[];
    var tttaccounts=[];
    for (const i in taccounts){
      var tobject={};
      tobject.regNo=taccounts[i].Attributes[1]['Value'];
      ttaccounts.push(taccounts[i].Attributes[0]['Value']);
      tttaccounts.push(tobject);
    }
    //console.log("TTaccounts : ", tttaccounts);
   /* var absent = tregister.filter(object1 => {
      return !taccounts.some(object2 => {
        console.log("Reg : ", object2.Attributes[0]['Value']);
        return object1.registrationNo === object2.Attributes[0]['Value'];
      });
    });*/
    //var objectmissing = tregister.filter((item)=>{taccounts.includes(account=>{ item.registrationNo!=account.Attributes[0]['Value']})});
    //var absent1 = tregister.filter((item)=>{tttaccounts.includes(account=>{ item.registrationNo!=account})});

    const results = tregister.filter(({ registrationNo: id1 }) => !tttaccounts.some(( {regNo: id2 }) => id2 === id1));

    //console.log("missing values : ", results);
    setMissingResults(results);

  }

  const fetchApplicationDetails=async(event, searchValue)=>{
    event.preventDefault();
    fetchCurrentYearDetails(searchValue);
  }

  const emailToggle = () => {
    //   console.log("clicked");
    setUpdateEmailCollapsed(!updateEmailCollapsed);
  }

  const handleEmailUpdateView =(event, item)=>{
    event.preventDefault();
    //console.log("item : ", item['Attributes'][1]['Value']);
   // setProfileDetails(item);
    setSelectedEmail(item['Attributes'][2]['Value']);
    setSelectedRegistrationNo(item['Attributes'][1]['Value']);
    setUpdateEmailCollapsed(!updateEmailCollapsed);
  }

  const handleCreateAccount=async(event, item)=>{
    event.preventDefault();
    try{
      var tphone="";
      if(item.areaCode!==""&& item.mobileNumber!==""){
      tphone=item.areaCode.toString()+item.mobileNumber.toString();
      }
      if(item.dateOfBirth===""){
        enqueueSnackbar("Date of Birth needs to be Updated first. Go to Student Profile and Update the Date of Birth",{variant:'warning'}); 
      }else if(!item.dateOfBirth.match(dobRegex)){
        enqueueSnackbar("Date of Birth needs to be Updated first. Go to Student Profile and Update the Date of Birth",{variant:'warning'}); 
      }else if(item.emailID===""){
        enqueueSnackbar("Email Address needs to be Updated first. Go to Student Profile and Update the Email Address",{variant:'warning'}); 
      }else if(item.perAddress===""){
        enqueueSnackbar("Address needs to be Updated first. Go to Student Profile and Update the Address",{variant:'warning'}); 
      }else if(!item.emailID.match(validRegex)){
        enqueueSnackbar("Email address is not in right format!",{variant:'warning'}); 
      }else if(!tphone.match(phoneRegex)){
        enqueueSnackbar("Area Code and Phone Number is not in right format!",{variant:'warning'}); 
      }else if(item.gender===""){
        enqueueSnackbar("Gender Status needs to be Updated first. Go to Student Profile and Update the Gender",{variant:'warning'}); 
      }else if(item.areaCode==="" || item.mobileNumber===""){
        enqueueSnackbar("Area Code and Mobile Number needs to be Updated first. Go to Student Profile and Update the Area Code and Mobile Number",{variant:'warning'}); 
      }else{
          setLoaded(false);
          const { idToken } = (await fetchAuthSession()).tokens ?? {};
        console.log("item :", item);
        const { registrationNo, gender, emailID, applicantName, dateOfBirth, areaCode, mobileNumber, applicationNo, applicationDate, perAddress } = item; 
          const params={
            registrationNo:registrationNo,
            gender: gender,
            emailID:emailID,
            applicantName:applicantName,
            dateOfBirth:dateOfBirth,
            applicationNo:applicationNo,
            applicationDate:applicationDate,
            areaCode:areaCode,
            mobileNumber:mobileNumber,
            perAddress:perAddress
          }
          console.log("Params : ", params);
          await axios.post(`${config.api.invokeURL}/dashboard/accounts/createnew`,params, {  
          headers: {
            Authorization: idToken,
            'x-api-key':config.api.key
          }
        }).then(async(response) => {
        // console.log("Response : ", response);
         if(response.status===215){
          enqueueSnackbar(response.data,{variant:'warning'}); 
          }else if(response.status===202){
          await fetchCurrentYearDetails();
          enqueueSnackbar("Login Account Created Successfully!",{variant:'success'}); 
          }
          setLoaded(true)
      }).catch((error)=>{
       // console.log("Error : ", error);
       enqueueSnackbar("Error Occured - " + error.message,{variant:'warning'}); 
      setLoaded(true);
      window.scrollTo(0,0);
    })
  }
    }catch(error){
     // console.log("Error : ", error);
     enqueueSnackbar("Error Occured - " + error.message,{variant:'warning'}); 
    setLoaded(true);
    window.scrollTo(0,0);
    }
    window.scrollTo(0,0);
  }

  const handleRecreateAccount=async(event, item)=>{
    event.preventDefault();
    try{
{
          setLoaded(false);
          const { idToken } = (await fetchAuthSession()).tokens ?? {};

          const registrationno=item.Attributes[1]['Value'];
          const params={
            registrationNo:registrationno,
          }
          console.log("Params : ", params);
          await axios.post(`${config.api.invokeURL}/dashboard/accounts/recreate`,params, {  
          headers: {
            Authorization: idToken,
            'x-api-key':config.api.key
          }
        }).then(async(response) => {
         //console.log("Response : ", response);
         if(response.status===202){
          await fetchCurrentYearDetails();
          enqueueSnackbar("Login Account Created Successfully!",{variant:'success'}); 
          }else{
            enqueueSnackbar(response.data,{variant:'warning'}); 
          }
          setLoaded(true)
      }).catch((error)=>{
       // console.log("Error : ", error);
       enqueueSnackbar("Error Occured - " + error.message,{variant:'warning'}); 
      setLoaded(true);
      window.scrollTo(0,0);
    })
  } 
    }catch(error){
     // console.log("Error : ", error);
     enqueueSnackbar("Error Occured - " + error.message,{variant:'warning'}); 
    setLoaded(true);
    window.scrollTo(0,0);
    }
    window.scrollTo(0,0);
  }

  const handleAccountProfile=async(event, item, task)=>{
    event.preventDefault();
    try{
      {
                setLoaded(false);
                const { idToken } = (await fetchAuthSession()).tokens ?? {};
      
                const emailID=item.Attributes[2]['Value'];
                
                const params={
                  emailID:emailID,
                  profile:task,
                }
                console.log("Params : ", params);
                await axios.post(`${config.api.invokeURL}/dashboard/accounts/update`,params, {  
                headers: {
                  Authorization: idToken,
                  'x-api-key':config.api.key
                }
              }).then(async(response) => {
               //console.log("Response : ", response);
               if(response.status===202){
                await fetchCurrentYearDetails();
                enqueueSnackbar("Profile Updated Successfully!",{variant:'success'}); 
                }
                setLoaded(true)
            }).catch((error)=>{
             // console.log("Error : ", error);
             enqueueSnackbar("Error Occured - " + error.message,{variant:'warning'}); 
            setLoaded(true);
            window.scrollTo(0,0);
          })
        } 
          }catch(error){
           // console.log("Error : ", error);
           enqueueSnackbar("Error Occured - " + error.message,{variant:'warning'}); 
          setLoaded(true);
          window.scrollTo(0,0);
          }
          window.scrollTo(0,0);
  }

return (
    <>
      {!loaded && <Preloader show={loaded ? false : true} />}
     {/*Update Email*/}
     <Modal show={updateEmailCollapsed} onHide={emailToggle}>
                    <Modal.Header toggle={emailToggle}>
                    Update - Email Address
                    </Modal.Header>
                    <Modal.Body>    
                    <div className="text-center">
                      <EmailUpdateModal emailToggle={emailToggle} fetchCurrentYearDetails={fetchCurrentYearDetails} selectedEmail={selectedEmail} selectedRegistrationNo={selectedRegistrationNo}/>
                    </div>
                    </Modal.Body>
                    <Modal.Footer>
                    <Button onClick={emailToggle}>
                Close
            </Button>
            </Modal.Footer>
        </Modal>

          <Breadcrumb>
            <Breadcrumb.Item>
            <Link to="/">
      <i className="fa fa-home" aria-hidden="true"></i>
      </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item> Staff</Breadcrumb.Item>
            <Breadcrumb.Item active> Account</Breadcrumb.Item>
          </Breadcrumb>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <h4>Account Management</h4>
        </div>
        {/*<div className="btn-toolbar mb-2 mb-md-0">
          <ButtonGroup>
            
            <Button variant="outline-primary" size="sm">Export</Button>
          </ButtonGroup>
      </div>*/}
      </div>

      <div className="table-settings mb-4">
      <Row className="justify-content-start align-items-center">
        <Col lg={4} className="mb-3">
          <Form.Group id="searchstud">               
            <Form.Select defaultValue="Batch" value={searchTopic} 
            size="lg" style={{fontSize:16, borderRadius:10, padding:2, width:"100%"}}
            onChange={(event)=>{setSearchTopic(event.target.value)}}>
            <option value="UserID">User ID</option>
            <option value="UserName">Name</option>
            <option value="EmailID">Email Address</option>
            <option value="UserAccount">User Account</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col  className="mb-3" lg={6}>
            {/*<InputGroup.Text>
              <FontAwesomeIcon icon={faSearch} />
            </InputGroup.Text>*/}
            {searchTopic==="UserAccount"?           
            <Form.Select value={searchValue} 
            size="lg" style={{fontSize:16, borderRadius:10, padding:2, width:"100%"}}
                onChange={(event)=>{setSearchValue(event.target.value)}}>
                  <option key="Residence" value="Residence">Residence</option>
                  <option key="College" value="College">College</option>
          </Form.Select>:
          <Form.Control type="text" placeholder="Search" value={searchValue} onChange={(event)=>{setSearchValue(event.target.value)}}/>}
        </Col>
        <Col  className="mb-3" lg={2}>
            <Button variant="primary" type="submit" onClick={(event)=>{fetchCurrentYearDetails()}}>Submit</Button>
          </Col>

      </Row>
    </div>

{/*missingResults.length>0?<><h3>{missingResults.length} - Records found without Login accounts</h3>
<Table ref={componentRef} hover responsive>
             <thead className='theadStyle'>
                <tr>
                <th>#</th>
                <th>Registration No</th>
                <th>Name</th>
                <th>Email</th>  
                <th>Mobile</th>     
                <th>Action</th>
                </tr>
            </thead>
            <tbody className='tbodyStyle'>
            {missingResults.map((item, index)=>

            <tr key={index} className='trStyle'>
            <th scope="row">{index+1}</th>
            <td>{item.registrationNo}</td>
            <td>{item.applicantName}</td>
            <td> {item.emailID}</td>
            <td>{item.areaCode}{item.mobileNumber}</td>
            <td> 
            <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
              <span className="icon icon-sm">
              <i class="fa fa-ellipsis-h icon-dark" aria-hidden="true"></i>

              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
             <Dropdown.Item onClick={(event)=>{handleCreateAccount(event,item)}} >
             <i className="fa fa-plus-circle" aria-hidden="true">Create Account</i>

              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
            </td>
            </tr>)}
            </tbody>
  </Table></>:null*/}

    <h3>{searchResults.length} - Records found with Login accounts</h3>
      <Table ref={componentRef} hover responsive>
             <thead className='theadStyle'>
                <tr>
                <th>
                    #
                </th>
                <th>
                    Registration No
                </th>
                <th>
                    Username
                </th>
                <th>Profile</th>
                <th>
                    Account Status
                    </th>  
                    <th>
                    Login Status
                    </th>  
                    <th>Action</th>
                </tr>
            </thead>
            <tbody className='tbodyStyle'> 
            {searchResults.length>0 && searchResults.map((item, index)=>

            <tr key={index} className='trStyle'>
            <th scope="row">
                {index+1}
            </th>
            <td>
                {item.Attributes[1]['Value']}
            </td>
            <td>
               {item.Attributes[2]['Value']}
            </td>
            <td>
               {item.Attributes[0]['Value']}
            </td>
            <td> 
              {item.Enabled?"Enabled":"Disabled"}
            </td>
            <td> 
              {item.UserStatus}
            </td>
            <td> 
            <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
              <span className="icon icon-sm">
              <i className="fa fa-ellipsis-h icon-dark" aria-hidden="true"></i>
                {/*<FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />*/}
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={(event)=>{handleEmailUpdateView(event,item)}} >
              <i className="fa fa-exchange" aria-hidden="true"> Change Email Address</i>

               {/*} <FontAwesomeIcon icon={faExchange} className="me-2" />*/}
              </Dropdown.Item>
              <Dropdown.Item onClick={(event)=>{handleRecreateAccount(event,item)}} >
               {/*} <FontAwesomeIcon icon={faExchange} className="me-2" />*/} 
               <i className="fa fa-plus" aria-hidden="true">Re-Create Account</i>
              </Dropdown.Item>
              {item.UserStatus==="CONFIRMED" && item.Attributes[0]['Value']==='Student' ? <Dropdown.Item onClick={(event)=>{handleAccountProfile(event,item,"Super Student")}} >
               {/*} <FontAwesomeIcon icon={faExchange} className="me-2" />*/} 
               <i className="fa fa-plus" aria-hidden="true">Update Super Student</i>
              </Dropdown.Item>:item.UserStatus==="CONFIRMED" && item.Attributes[0]['Value']==='Super Student' ?<Dropdown.Item onClick={(event)=>{handleAccountProfile(event,item,"Student")}} >
               {/*} <FontAwesomeIcon icon={faExchange} className="me-2" />*/} 
               <i className="fa fa-plus" aria-hidden="true">Revoke Super Student</i>
              </Dropdown.Item>:null}

            </Dropdown.Menu>
          </Dropdown>
            </td>
            </tr>)}
            </tbody>
      </Table>
    </>
  );
};
