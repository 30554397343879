import React, { useEffect, useContext,useState } from 'react';
import axios from "axios";
import { ChartsGoogle } from './ChartsGoogle';
import Preloader from '../../components/Preloader/Preloader';
import {Row, Col, Breadcrumb} from 'react-bootstrap';
import { useSnackbar } from 'notistack';
import { Link } from "react-router-dom";
import { fetchAuthSession } from 'aws-amplify/auth';

const config = require('../../config.json');

export default function StudentsInsights() {

    const [loaded, setLoaded] = useState(true);
    const [regYearCount, setRegYearCount] = useState();
    const [googleRegData, setGoogleRegData]= useState();
    const [isRegYearCount, setIsRegYearCount]=useState(false);
    const { enqueueSnackbar } = useSnackbar();

    useEffect(()=>{
        fetchRegDetails()
    },[])

    const fetchRegDetails=async()=>{
        try{      
            
              setLoaded(false);
              const { idToken } = (await fetchAuthSession()).tokens ?? {};
              await axios.get(`${config.api.invokeURL}/insights/students`, {
              headers: {
                Authorization: idToken,
                'x-api-key':config.api.key
              }
            }).then(async(response) => {
           //  console.log("response : ", response);
             processData(response.data);
             enqueueSnackbar(response.data.length + " - student's records found!",{variant:'success'}); 
            setLoaded(true);
          }).catch((error)=>{
            enqueueSnackbar("Error Occured - " + error.message,{variant:'warning'}); 
          setLoaded(true);
        })
      
          }catch(error){
            enqueueSnackbar("Error Occured - " + error.message,{variant:'warning'}); 
          setLoaded(true);
          }
        }

    const processData=(rawData)=>{
        var regChartHeader=[];
        var regChartValues=[];
        const chartHearder=["Year","Registration Count"];
        regChartHeader.push(chartHearder);

        for(const data in rawData){
            const ttt=[data, rawData[data]];
            regChartHeader.push(ttt);
           // regChartHeader.push(rawData[data]);

            regChartValues.push(rawData[data]);
        //    console.log(data," - ", rawData[data]);

        }
        const regChartData={};
        regChartData.labels=regChartHeader;
        regChartData.series=regChartValues;
        setRegYearCount(regChartData);
        //console.log("Data : ", regChartData);
        var tdata=[]
        tdata.push(chartHearder);
        tdata.push(regChartHeader);
        tdata.push(regChartValues);
        setGoogleRegData(regChartHeader);
        setIsRegYearCount(true);

    }

  return (
    <>
        <Preloader show={loaded ? false : true} />
        <Breadcrumb>
          <Breadcrumb.Item> <Link to="/">
      <i className="fa fa-home" aria-hidden="true"></i>
      </Link></Breadcrumb.Item>
          <Breadcrumb.Item>Students Details</Breadcrumb.Item>
          <Breadcrumb.Item active>Search</Breadcrumb.Item>
        </Breadcrumb>
        {isRegYearCount?
        <Row className="justify-content-md-center">
        <Col xs={12} className="mb-4 d-none d-sm-block">
        <ChartsGoogle regYearCount={googleRegData} titleMsg="Year over Year - Registration Count"/>
            </Col>
        </Row>:null}
    </>
)
}
