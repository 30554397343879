
const Header = ({ title, subtitle }) => {
  return (
    <div mb="30px">
      <h2
        fontWeight="bold" >
        {title}
      </h2>
      <h5>
        {subtitle}
      </h5>
    </div>
  );
};

export default Header;
