import React, {useEffect, useState} from 'react'
import {Card, Row, Col, Form } from 'react-bootstrap';

import { courseDetailsList } from '../../ProData';

export default function MarkSheetForm(props) {
    const {courseDetails, setCourseDetails, formType, profileDetails} = props;
    const [viewOnly, setViewOnly] = useState(false);
    const [courseName, setCourseName] = useState([]);
    const [courseYear, setCourseYear] = useState([]);
    const [filteredCourse, setFilteredCourse] = useState([]);
    //const [optedCourse, setOptedCourse] = useState();

    useEffect(()=>{
      //console.log("props :", props);
        initializeValues();
        if(formType==="Add" || formType==="Edit"){
            setViewOnly(false);
        }else{
            setViewOnly(true);
        }
    },[])

    const initializeValues=()=>{
        const tempregno=profileDetails.registrationNo;
        const tempcoursecode = tempregno.substring(2,5);
        const tempfilter=courseDetailsList.filter((item)=>item.degreeCode===tempcoursecode);
        //console.log("filtered :", tempfilter);
        setFilteredCourse(tempfilter);
        var uniqueArray = removeDuplicates("courseYear");
        setCourseYear(uniqueArray);
        //console.log("Opeted : ", uniqueArray[0]);
        setCourseDetails({...courseDetails, 
            courseId:uniqueArray[0].courseId,
            courseCode:uniqueArray[0].courseCode, courseCredit:uniqueArray[0].courseCredit,courseName:uniqueArray[0].courseName,courseType:uniqueArray[0].courseType,courseYear:uniqueArray[0].courseYear});
        handleCourseYearSelection("First", tempfilter);
      }
    
      const removeDuplicates=(tprop)=> {
        var newArray = [];
        var lookupObject  = {};
        for(var i in courseDetailsList) {
           lookupObject[courseDetailsList[i][tprop]] = courseDetailsList[i];
        }
        for(i in lookupObject) {
            newArray.push(lookupObject[i]);
        }
         return newArray;
    }

    const handleCourseYearSelection =(tValue, tempfilter)=>{
        const tempList = tempfilter.filter((item)=>item.courseYear===tValue);
        setCourseDetails({...courseDetails, courseYear:tValue});
        setCourseName(tempList);
    }

    const handleMarkValidation = (event)=>{
        const tvalue=parseFloat(event.target.value);
       // console.log("Opted course : ", courseDetails);
       if(tvalue>0){
        const temp=tvalue*courseDetails.courseCredit;
        const tresult=tvalue<40?"Failed":tvalue>=40 && tvalue<=49 ? "Third Class" : tvalue>=50 && tvalue<=59 ? "Second Class": tvalue>=60 && tvalue<=69 ? "First Class":"Distinction";
        setCourseDetails({...courseDetails, 
            courseMarks:event.target.value,
            coursePoints: temp,
            courseResult:tresult
        });
       }else{
        setCourseDetails({...courseDetails, 
            courseMarks:event.target.value,
            coursePoints: 0,
            courseResult:"-"
        });
       }
    }

    const handleCourseNameSelection = (event)=>{
       // console.log("Course : ", event.target.value);
        const temp=courseName.filter((item)=>item.courseName===event.target.value);
        setCourseDetails(temp[0]);   
    }

  return (
    <div>
    <Card>
      <Card.Body>
        <Form>
        <Row>
            <Col md={4} className="mb-3">
              <Form.Group id="courseYear">
                <Form.Label>Course Year</Form.Label>
                <Form.Select size="lg" style={{fontSize:16, borderRadius:10, padding:8, width:"100%"}}
                    value={courseDetails.courseYear} disabled={viewOnly}
                    onChange={(event)=>{handleCourseYearSelection(event.target.value, filteredCourse)}}>
                        {courseYear.map((item, index)=>
                  <option key={index} value={item.courseYear}>{item.courseYear}</option>)}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col md={8} className="mb-3">
              <Form.Group id="courseName">
                <Form.Label>Course Name</Form.Label>
                <Form.Select size="lg" style={{fontSize:16, borderRadius:10, padding:8, width:"100%"}}
                    value={courseDetails.courseName} disabled={viewOnly}
                    onChange={(event)=>{handleCourseNameSelection(event)}}>
                        {courseName.map((item, index)=>
                  <option key={index} value={item.courseName}>{item.courseCode} - {item.courseName}</option>)}
                </Form.Select>
              </Form.Group>
            </Col>
          </Row> 
          <Row>
            <Col md={6} className="mb-3">
              <Form.Group id="marks">
                <Form.Label>Marks</Form.Label>
                <Form.Control required type="number" placeholder="Enter your marks...." value={courseDetails.courseMarks}
                  disabled={viewOnly}
                  onChange={(event)=>{handleMarkValidation(event)}}  
               />
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group id="points">
                <Form.Label>Credits</Form.Label>
                <Form.Control required type="text" value={courseDetails.courseCredit}
                  placeholder='-' disabled
                 // onChange={(event)=>{setCourseDetails({...courseDetails, coursePoints:event.target.value})}}
                />
              </Form.Group>
            </Col>
            </Row>
            <Row>
            <Col md={6} className="mb-3">
              <Form.Group id="points">
                <Form.Label>Points</Form.Label>
                <Form.Control required type="text" value={courseDetails.coursePoints}
                  placeholder='-' disabled
                 // onChange={(event)=>{setCourseDetails({...courseDetails, coursePoints:event.target.value})}}
                />
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group id="points">
                <Form.Label>Result</Form.Label>
                <Form.Control required type="text" value={courseDetails.courseResult}
                  placeholder='-' disabled
                 // onChange={(event)=>{setCourseDetails({...courseDetails, courseResult:event.target.value})}}
                />
              </Form.Group>
            </Col>
          </Row>
</Form>
</Card.Body>
</Card>
    </div>
  )
}
