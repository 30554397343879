import React, { useState, useContext, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import {Button, Modal, Breadcrumb} from 'react-bootstrap';
import axios from 'axios';
import { ProductContext } from '../../ProductProvider';
import AddSlider from './AddSlider';
import logo from '../../assets/images/logo/COLLEGELOGO.jpeg'
import ImageDisplay from './ImageDisplay';
import Preloader from '../../components/Preloader/Preloader';
import '../../styles.css';
import {Helmet} from 'react-helmet-async';
import { Link } from "react-router-dom";
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { faHome } from '@fortawesome/free-solid-svg-icons';
import { fetchAuthSession } from 'aws-amplify/auth';
import AddCompressSlider from './AddCompressSlider';

const config = require('../../config.json');

// TODO remove, this demo shouldn't need to reset the theme.

export default function SliderViewer() {
    const { validateLogin, loggedUser} = useContext(ProductContext);
    const [loaded, setLoaded] = useState(false);
    const [galleryImages, setGalleryImages] = useState([]);
    const [open, setOpen] = useState(false);
    const [imageFile, setImageFile] = useState('');
    const [profileImage, setProfileImage] = useState(logo);
    const [newFileName, setNewFileName]=useState('');
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        if(loggedUser.isAuthenticated){
          fetchGalleryImages();
        }else{
          validateLogin();
        }
      }, [loggedUser])

    const fetchGalleryImages =async()=>{
    try{
            setLoaded(false);
            const fileName='images/slider';
            const bucketName="images.vidyajyoticollege.in";
            await axios.get(`${config.api.invokeURL}/gallery/slider?fileName=${fileName}&bucketName=${bucketName}`,{
            headers: {
              //Authorization: access_token,
              'x-api-key': config.api.key
            }}, 
            ).then((response)=>{
            const finalList=[];
            if(response.data.length>0){
              for (const i in response.data){
                const tValue=response.data[i];
                const tPathName=tValue.split("/");
                const tBreakUp=tPathName[2];
                const tempValue={};
                tempValue.fullPathName=response.data[i];
                tempValue.fileName=tBreakUp;
                finalList.push(tempValue);
              }
            }
            setGalleryImages(finalList);
            enqueueSnackbar(response.data.length + " - Images are found in Slider section",{variant:'success'})
            setLoaded(true);
            window.scrollTo(0,0); 
        }).catch((error)=>{
            enqueueSnackbar("Error Occured - " + error.message,{variant:'warning'}) 
            setLoaded(true);
            window.scrollTo(0,0); 
        })
    }catch(error){
       enqueueSnackbar("Error Occured - " + error.message,{variant:'warning'});
        setLoaded(true);
        window.scrollTo(0,0); 
    }
    setLoaded(true);
    }

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const postOnS3 = async(event)=>{
        event.preventDefault();
        try{ 
            if(imageFile===""||newFileName==="")
            {
                enqueueSnackbar("Enter all the details before Adding!",{variant:'info'})
                setLoaded(true);
                window.scrollTo(0,0);   
    
            }else{
            setLoaded(false);
            const fileName=newFileName
         const tnewFileName=fileName+".jpeg";
         const keyName='/images/slider';
          const bucketName=config.s3.bucketName+keyName;
          var file = imageFile;
          var blob = file.slice(0, file.size, 'image/jpeg'); 
          const newFile = new File([blob], tnewFileName, {type: 'image/jpeg'});
          const { idToken } = (await fetchAuthSession()).tokens ?? {};
          await axios.get(`${config.api.invokeURL}/presigned?fileName=${tnewFileName}&bucketName=${bucketName}`,{
               headers:{
                  Authorization: idToken,
                  'x-api-key': config.api.key
               }
           }).then(async(response)=>{
              const res=await fetch(response.data.uploadURL, { method: 'PUT',
               headers:{
                   "Content-Type":"image/jpeg"
                 },body: newFile});
                 if(res.status===200){
                    fetchGalleryImages();
                     setProfileImage(newFileName);
                    handleClose();
                 }
            }).catch((error)=>{
                enqueueSnackbar("Error Occured - " + error.message,{variant:'warning'})
                setLoaded(true);
                window.scrollTo(0,0);   
           })
        }
        }catch(error){
          //console.log("error1:", error);
          enqueueSnackbar("Error Occured - " + error.message,{variant:'warning'}) 
          setLoaded(true);
          window.scrollTo(0,0);   
        }
        setLoaded(true);    
    }

    const handleDeleteImage = async(event, imageName)=>{
        event.preventDefault();
        setLoaded(false);
      
       // const imageid=imageName.substring(12,imageName.length);
       try{
           if(galleryImages.length<2){
            enqueueSnackbar("Delete not allowed! Only one image left in Slider",{variant:'info'});
           }else{
            //const session = await Auth.currentSession();
            //const access_token=session.idToken.jwtToken;
            //console.log("image : ", imageName  );
            const { idToken } = (await fetchAuthSession()).tokens ?? {};
        await axios.get(`${config.api.invokeURL}/gallery/remove?imageName=${imageName}`,{
            headers:{
                Authorization: idToken,
                'x-api-key': config.api.key
            }
        }).then((response)=>{
            //console.log(response);
            //setGalleryImages(response.data);
            enqueueSnackbar("Deleted Successfully",{variant:'success'});
            window.scrollTo(0,0);
            fetchGalleryImages();
        }).catch((error)=>{
           // setDispMsg(error.message);
           enqueueSnackbar("Error Occured - " + error.message,{variant:'warning'});
            setLoaded(true);
            window.scrollTo(0,0); 
        })
    }
    }catch(error){
      enqueueSnackbar("Error Occured - " + error.message,{variant:'warning'});
        setLoaded(true);
        window.scrollTo(0,0); 
    }
    setLoaded(true);
    }
  
  return (
    <>
    <main>
    <Preloader show={loaded ? false : true} />
    <Helmet>
      <title>Slider - Admin Office - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies</title>
      <meta name="description" content="Slider - Admin Office - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies" />
      <meta name="keywords" content="Slider - Admin Office - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies" /> 
      <link rel="canonical" href="/slider-viewer" />
    </Helmet>
    <Breadcrumb>
    <Breadcrumb.Item>
      <Link to="/">
      <i className="fa fa-home" aria-hidden="true"></i>
      </Link>
    </Breadcrumb.Item>
    <Breadcrumb.Item >
      Websites
    </Breadcrumb.Item>
    <Breadcrumb.Item >
      Slider
    </Breadcrumb.Item>
    </Breadcrumb>
    <h3>Slider Images</h3>
        <ImageDisplay galleryImages={galleryImages} handleDeleteImage={handleDeleteImage} 
          postOnS3={postOnS3} titleName="Slider Layout" subTitleName="Live Slider images in website"
          handleOpen={handleOpen}/>       
    <Modal show={open} onHide={handleClose} maxWidth={'md'} fullWidth>
      <Modal.Header closeButton={true}>
      <Modal.Title>Add Slider Images</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <AddCompressSlider newFileName={newFileName} setNewFileName={setNewFileName} setImageFile={setImageFile} profileImage={profileImage} />
      </Modal.Body>
      <Modal.Footer>
        <div style={{display:"flex", justifyContent:"end"}}>
          <Button type="submit" className='buttonStyle' onClick={event=>postOnS3(event)}>
            Save
          </Button>
          <Button type="submit" className='buttonStyle' onClick={event=>handleClose(event)}>
            Cancel
          </Button>
        </div>
        </Modal.Footer>
      </Modal>
      </main>
    </>
  );
}