import React, {useState, useEffect, useContext} from 'react';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import AdminForm from './AdminForm';
//import Header from '../../components/header/Header';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { faEye, faEdit, faHome, faExchange, faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import {Table, Button, Modal, Container, Breadcrumb, Dropdown, ButtonGroup} from 'react-bootstrap';
import Preloader from '../../components/Preloader/Preloader';
import { ProductContext } from '../../ProductProvider';
import '../../styles.css';
import {Helmet} from 'react-helmet-async';
import { Link, useNavigate } from "react-router-dom";
import { fetchAuthSession } from 'aws-amplify/auth';

const config = require('../../config.json');

export default function ListAdmins() {
    const { enqueueSnackbar } = useSnackbar();
    const { validateLogin, loggedUser} = useContext(ProductContext);
    const [loaded, setLoaded] = useState(false);
    const [viewForm, setViewForm] = useState(false);
    const [adminDetails, setAdminDetails] = useState([]);
    const [action, setAction] = useState('View');
    const [selectedItem, setSelectedItem] = useState({});
    const handleOpenViewForm = () => setViewForm(true);
    const handleCloseViewForm = () => setViewForm(false);
    const navigate = useNavigate();

    useEffect(()=>{
      if(loggedUser.isAuthenticated && (loggedUser.profile==="Admin" || loggedUser.profile==="Super Admin")){
        fetchAdmins();
      }else{
        //validateLogin();
        navigate("/");
      }
      },[loggedUser])

    const fetchAdmins=async()=>{
        setLoaded(false);
        try{
              //const session = await Auth.currentSession();
              //const access_token=session.idToken.jwtToken;
              const { idToken } = (await fetchAuthSession()).tokens ?? {};
              const searchOption="UserStatus";
              const clientID="VJCollege";
              const currentStatus="Active";
              await axios.get(`${config.api.invokeURL}/dashboard/officeform?fetchKey=${currentStatus}&fetchOption=${searchOption}&clientID=${clientID}`, {
                headers: {
                  Authorization: idToken,
                  'x-api-key':config.api.key
                }
              }).then(async(response) => {
               //console.log("response : ", response);
                if(response.data.length>0)
                {
                    //processAdminData(response.data);
                    setAdminDetails(response.data);
                    enqueueSnackbar(response.data.length + " - Active Admin Details loaded!",{variant:'success'});

                  setLoaded(true);
                }
                setLoaded(true);
            }).catch((error)=>{
                enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});
                setLoaded(true);
          })
    
        }catch(error){
            enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});
            setLoaded(true);
      }
    }

    const processAdminData=(item)=>{
        const test=[];
        for(const i in item){
            var testv={};
            testv=item[i];
            //testv.length=item[i].fileName.length;
            testv.id=parseInt(i)+1;
            test.push(testv);
            testv={};
        }
        //console.log("testdata :", test);
        setAdminDetails(test);
        setLoaded(true);
    
    }

    const handleAdminView = (event, type, item)=>{
    event.preventDefault();
    //const itemtest=adminDetails.filter((admin)=>admin.id===id);
    //const item=itemtest[0];    
    try{
        setAction(type);
        handleOpenViewForm();
        if(type==="View"){
        setSelectedItem(item);
        }
    }catch(error){
        console.log("Error : ", error);
    }
    }

    const handleRecreateAccount=async(event, item)=>{
      event.preventDefault();
      try{
      {
            setLoaded(false);
            const { idToken } = (await fetchAuthSession()).tokens ?? {};
  
            //const registrationno=item.Attributes[0]['Value'];
            const { userID, userEmailID,userName, registeredDate, areaCode, userPhoneNo, userRole } = item; 

            const params={
              userID:userID,
              userEmailID:userEmailID,
              userName:userName,
              registeredDate:registeredDate,
              areaCode:areaCode,
              userPhoneNo:userPhoneNo,
              userRole:userRole
            }
            await axios.post(`${config.api.invokeURL}/dashboard/officeform/accounts/recreate`,params, {  
            headers: {
              Authorization: idToken,
              'x-api-key':config.api.key
            }
          }).then(async(response) => {
           //console.log("Response : ", response);
           if(response.status===202){
           // await fetchCurrentYearDetails();
            enqueueSnackbar("Login Account Reset Successfully!",{variant:'success'}); 
            }else{
              enqueueSnackbar(response.data,{variant:'warning'}); 
            }
            setLoaded(true)
        }).catch((error)=>{
         // console.log("Error : ", error);
         enqueueSnackbar("Error Occured - " + error.message,{variant:'warning'}); 
        setLoaded(true);
        window.scrollTo(0,0);
      })
    } 
      }catch(error){
       // console.log("Error : ", error);
       enqueueSnackbar("Error Occured - " + error.message,{variant:'warning'}); 
      setLoaded(true);
      window.scrollTo(0,0);
      }
      window.scrollTo(0,0);
    }
  
  
  return (
    <>
    {!loaded && <Preloader show={loaded ? false : true} />}
    <Helmet>
      <title>Admin Section - Admin Office - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies</title>
      <meta name="description" content="Admin Section - Admin Office - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies" />
      <meta name="keywords" content="Admin Section - Admin Office - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies" /> 
      <link rel="canonical" href="/list-admins" />
    </Helmet>
    <Breadcrumb>
    <Breadcrumb.Item>
      <Link to="/">
      <i className="fa fa-home" aria-hidden="true"></i>
      </Link>
    </Breadcrumb.Item>
    <Breadcrumb.Item >
      Profile
    </Breadcrumb.Item>
    <Breadcrumb.Item >
      Admins
    </Breadcrumb.Item>
  </Breadcrumb>
        <h3>Admin Section</h3>
        {/* Admin Form */}
    <Modal show={viewForm} onHide={handleCloseViewForm} maxWidth={'md'} size="lg" fullWidth>
    <Modal.Header closeButton>
        <Modal.Title>Admin Form</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <AdminForm formType={action} selectedItem={selectedItem} handleCloseForm={handleCloseViewForm} searchResult={adminDetails} setSearchResult={setAdminDetails} /> 
        </Modal.Body>
    </Modal>
    <div m="20px">
     {/*} <Header
        title="Admin Details"
        subtitle="List of admin details"
  />*/}
        <Container style={{padding:"20px"}}>
        <Button type="submit" className='buttonStyle'
            onClick={event=>handleAdminView(event, "New")}>
          New
        </Button>
      </Container>
      <div className='card table-wrapper'>
       {loaded && <Table striped bordered hover>
        <thead className='theadStyle'>
            <tr>
                <th>#</th>
                <th>User Name</th>
                <th>Email ID</th>
                <th>Phone No</th>
                <th>Role</th>
                <th>Actions</th>
            </tr>
        </thead>
        <tbody className='tbodyStyle'>
        {adminDetails.map((item,index)=>(
          <tr className='trStyle' key={index}>
          <td>{item.userID}</td>
              <td>{item.userName}</td>
              <td>{item.userEmailID}</td>
              <td>{item.userPhoneNo}</td>
              <td>{item.userRole}</td>
              <td> {/*<div style={{display: 'flex', flexDirection:'row'}}>                 
              <div style={{paddingLeft:10}}><FontAwesomeIcon icon={faEye} className="me-2" onClick={event=>handleAdminView(event,"View", item.id)}/></div>
              <div style={{paddingLeft:10}}><FontAwesomeIcon icon={faEdit} className="me-2" onClick={event=>handleAdminView(event, "Edit", item.id)}/></div>
        </div>*/}
            <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
              <span className="icon icon-sm">
                <i className="fa fa-ellipsis-h icon-dark" aria-hidden="true"></i>
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {/*<Dropdown.Item onClick={(event)=>{handleEmailUpdateView(event,item)}} >
                <FontAwesomeIcon icon={faExchange} className="me-2" /> Change Email Address
              </Dropdown.Item>*/}
              <Dropdown.Item onClick={(event)=>{handleAdminView(event,"View",item)}} >
              <i className="fa fa-eye me-2" aria-hidden="true">View Account</i>
              </Dropdown.Item>
              <Dropdown.Item onClick={(event)=>{handleAdminView(event,"Edit",item)}} >
              <i className="fa fa-pencil me-2" aria-hidden="true">Edit Account</i>
              </Dropdown.Item>
              <Dropdown.Item onClick={(event)=>{handleRecreateAccount(event,item)}} >
                <i className="fa fa-exchange me-2" aria-hidden="true">Reset Login</i>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
            </td>
        </tr>
      ))}
      </tbody>
      </Table>
      }
      </div>
    </div>
    </>
  )
}
