import React, {useEffect, useState} from 'react';
import { Col, Row, Card, Button, Table, FormGroup, Form } from 'react-bootstrap';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { useSnackbar } from 'notistack';

export default function LanguageInfo(props) {
  const [localStudy, setLocalStudy]=useState({id:"",language:"",reading:"Good",writing:"Good",speaking:"Good"});
  const {form, setForm,  formType} = props;
  //const [newProfileImage, setNewProfileImage]=useState('defaultAvatar.jpg')
  //  const [loaded, setLoaded] = useState(true);
  const [viewOnly, setViewOnly] = useState(true);
  const { enqueueSnackbar } = useSnackbar();

useEffect(() => {
  if(formType==="Edit"){
    setViewOnly(false);
    generateApplicationNo();
  }else{
    setViewOnly(true);
  }
}, [])

const generateApplicationNo=()=>{
  const current_datetime = new Date();
  var tempUserId="";
  const date_year = (current_datetime.getFullYear()).toString().substring(2,4);
  var date_month = (current_datetime.getMonth() + 1).toString();
  var month_value="";
  date_month.length===1? (month_value=month_value.concat(0,date_month)):(month_value=date_month);
  var date_date = (current_datetime.getDate().toString());
  var date_value="";
  date_date.length===1?(date_value=date_value.toString().concat(0,date_date)):(date_value=date_date);
  var date_hours = (current_datetime.getHours().toString());
  var hours_value="";
  date_hours.length===1?(hours_value=hours_value.toString().concat(0,date_hours)):(hours_value=date_hours);
  var date_minutes = (current_datetime.getMinutes().toString());
  var minutes_value="";
  date_minutes.length===1 ?(minutes_value=minutes_value.toString().concat(0,date_minutes)):(minutes_value=date_minutes);
  var date_seconds = (current_datetime.getSeconds().toString());
  var seconds_value="";
  date_seconds.length===1 ?(seconds_value=seconds_value.toString().concat(0,date_seconds)):(seconds_value=date_seconds);

  const accountNo = tempUserId.concat(date_year,month_value, date_value, hours_value, minutes_value, seconds_value);
  //setUserId(accountNo);
  //setMemberInfo({...memberInfo, userId:accountNo});
  //return accountNo;
  //console.log("acc : ", accountNo);
  //setLocalStudy({...localStudy, id:accountNo});
  setLocalStudy({id:accountNo, language:"",reading:"Basic",writing:"Basic",speaking:"Basic"});


}

  const handleStudyAdd =async(event)=>{
    event.preventDefault();
    //const tempid=await generateApplicationNo();
    //await setLocalStudy({...localStudy, id:tempid});
    if(localStudy.language===""||localStudy.reading===""||localStudy.writing===""||localStudy.speaking===""){
      enqueueSnackbar("Acadamic Fields cannot be empty. Enter all Fields!",{variant:'info'});
    }else{
      //setStudyDetails([...studyDetails, localStudy]);
      await setForm({...form,
        languageDetails:[...form.languageDetails, localStudy]});
        //setLocalStudy({id:"", language:"",reading:"Basic",writing:"Basic",speaking:"Basic"});
        generateApplicationNo();
     //   calculateProgressiveValues(memberInfo);
    }
  }

  const handleRemoveStudy=async(event, member)=>{
    event.preventDefault();
    //console.log("item : ", member);
      let tempCart = [...form.languageDetails];
      tempCart = tempCart.filter(item => item.id !==member.id);
      //console.log("temp cart :", tempCart);
     await setForm({
      ...form,
      languageDetails:[...tempCart]
    });
   // calculateProgressiveValues(memberInfo);
    // setStudyDetails([...tempCart]);
  }

  return (
    <div>
      <Card>
      {!viewOnly?<Card.Body>
        <Form>
        <h5>Language Proficiency</h5>
          <Row>
            <Col> 
              <div className="navbar-bg" style={{marginBottom:'20px'}}>
                <FormGroup style={{overflow:"visible"}}>
              <div>
            <Row>
            <Col xs={9} lg={3}>
            <Form.Group id="language">
                <Form.Label>Language</Form.Label>
                  <Form.Control
                        type="text"
                        name="institutionname"
                        placeholder="Language"
                        onChange={(event)=>{setLocalStudy({...localStudy,
                          language:(event.target.value).toUpperCase()})}}
                          value={localStudy.language}
                 />
            </Form.Group>
                  </Col>
                    <Col xs={9} lg={2}>
                    <Form.Group id="reading">
                    <Form.Label>Reading</Form.Label>
                    <Form.Select size="lg" style={{fontSize:16, borderRadius:10, padding:8, width:"100%"}}
                        onChange={(event)=>{setLocalStudy({...localStudy,
                            reading:event.target.value})}}
                            value={localStudy.reading}
                                  >
                  <option value="Basic">Basic</option>
                  <option value="Average">Average</option>
                  <option value="Good">Good</option>
                </Form.Select>
                    </Form.Group>
                    </Col>
                    <Col xs={9} lg={2}>
                    <Form.Group id="writing">
                    <Form.Label>Writing</Form.Label>
                    <Form.Select size="lg" style={{fontSize:16, borderRadius:10, padding:8, width:"100%"}}
                        onChange={(event)=>{setLocalStudy({...localStudy,
                            writing:event.target.value})}}
                            value={localStudy.writing}
                                  >
                  <option value="Basic">Basic</option>
                  <option value="Average">Average</option>
                  <option value="Good">Good</option>
                </Form.Select>
                    </Form.Group>
                    </Col>
                    <Col xs={9} lg={2}>
                    <Form.Group id="speaking">
                    <Form.Label>Speaking</Form.Label>
                    <Form.Select size="lg" style={{fontSize:16, borderRadius:10, padding:8, width:"100%"}}
                        onChange={(event)=>{setLocalStudy({...localStudy,
                            speaking:event.target.value})}}
                            value={localStudy.speaking}
                                  >
                  <option value="Basic">Basic</option>
                  <option value="Average">Average</option>
                  <option value="Good">Good</option>
                </Form.Select>
                    </Form.Group>
                    </Col>
                 <Col xs={9} lg={1}>
                 <Button title="Add User" onClick={(event)=>{handleStudyAdd(event)}} >ADD</Button>
                 </Col>
               </Row>
                  </div>
                 </FormGroup>
       </div>
       </Col>           
          </Row>
          </Form>
      </Card.Body>:null}
      <p>Degree Details:</p>
  {form.languageDetails ? <Table hover responsive>
  <thead className='theadStyle'>
    <tr>
      <th>
        #
      </th>
      <th>Language</th>
      <th>Reading</th>
      <th>Writing</th>
      <th>Speaking</th>
    </tr>
  </thead>
  <tbody className='tbodyStyle'>
  {form.languageDetails.map((item, index)=>
    <tr key={index} className='trStyle'>
      <th scope="row">
      {index+1}
      </th>
      <td>{item.language}</td>
      <td>{item.reading}</td>
      <td>{item.writing}</td>
      <td>{item.speaking}</td>
     {!viewOnly? <td>
      <i className="fa fa-trash-o me-2" aria-hidden="true" onClick={(event)=>{handleRemoveStudy(event, item)}}> </i>
  </td>:null}
    </tr>)}
  </tbody>
</Table>:<p>No Degree details found</p>}
    </Card>
    </div>
  )
}
