import React, { useState } from "react";
import { Form, Row, Col } from 'react-bootstrap';
import Preloader from "../../components/Preloader/Preloader";
import Resizer from "react-image-file-resizer";

export default function AddCompressSlider(props) {
    const {setNewFileName, newFileName, setImageFile, profileImage}=props;
    const inputRef = React.useRef();
    const [loaded, setLoaded] = useState(true);

    const handleFileSelection = async(event)=>{
        setLoaded(false);
          event.preventDefault();
          if (event.target.files && event.target.files.length > 0) {
            try {
                await Resizer.imageFileResizer(
                  event.target.files[0],
                  1500,
                  1500,
                  "JPEG",
                  100,
                  0,
                  (uri) => {
                    //console.log(uri);
                    setImageFile(uri);
                    //console.log("compressed");
                   // this.setState({ newImage: uri });
                  },
                  "blob",
                  400,
                  400
                );
              } catch (err) {
                console.log(err);
              }
           // const image = await resizeFile(file);
            //console.log(image);
           // setImageFile(image);
            var tprofile1 = document.getElementById("img1");
            tprofile1.src=URL.createObjectURL(event.target.files[0]);
        }
        setLoaded(true);
    }

  return (
  <>
    <Preloader show={loaded ? false : true} />
      <div m="50px">
        <div className="text-center">
          <img id="img1" alt="Selected Image" src={profileImage}
          style={{ height: 180, width: 180 }} />
        </div>
        <Form.Group as={Row} className="mb-3" controlId="formPlaintextFile">
          <Form.Label column sm="4">
            File Name
          </Form.Label>
          <Col sm="8">
            <Form.Control type="text" placeholder="Enter File Name" 
            controlId="formFileName"
            onChange={event=>setNewFileName(event.target.value)}
            value={newFileName} />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3" controlId="formPlaintextImage">
          <Form.Label column sm="4">
            Select Image
          </Form.Label>
          <Col sm="8">
          <Form.Control type="file"
            controlId="formFileImage"  
            accept="image/*" ref={inputRef}
            onChange={handleFileSelection} />
          </Col>
        </Form.Group>
      </div>
    </>
  )
}
