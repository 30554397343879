import React, {useEffect, useState } from 'react';
import { Col, Row, Card, Button, Table, FormGroup, Form } from 'react-bootstrap';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { useSnackbar } from 'notistack';


export default function AcademicInfo(props) {
  const [localStudy, setLocalStudy]=useState({id:"",instituteName:"", degree:"",studyYear:""});
  const [pastoral, setPastoral] = useState('Pastoral');
  const {form, setForm,  formType} = props;
  const [viewOnly, setViewOnly] = useState(true);
  const { enqueueSnackbar } = useSnackbar();

useEffect(() => {
  if(formType==="Edit"){
    setViewOnly(false);
    generateApplicationNo();
  }else{
    setViewOnly(true);
  }
}, [])

const generateApplicationNo=()=>{
  const current_datetime = new Date();
  var tempUserId="";
  const date_year = (current_datetime.getFullYear()).toString().substring(2,4);
  var date_month = (current_datetime.getMonth() + 1).toString();
  var month_value="";
  date_month.length===1? (month_value=month_value.concat(0,date_month)):(month_value=date_month);
  var date_date = (current_datetime.getDate().toString());
  var date_value="";
  date_date.length===1?(date_value=date_value.toString().concat(0,date_date)):(date_value=date_date);
  var date_hours = (current_datetime.getHours().toString());
  var hours_value="";
  date_hours.length===1?(hours_value=hours_value.toString().concat(0,date_hours)):(hours_value=date_hours);
  var date_minutes = (current_datetime.getMinutes().toString());
  var minutes_value="";
  date_minutes.length===1 ?(minutes_value=minutes_value.toString().concat(0,date_minutes)):(minutes_value=date_minutes);
  var date_seconds = (current_datetime.getSeconds().toString());
  var seconds_value="";
  date_seconds.length===1 ?(seconds_value=seconds_value.toString().concat(0,date_seconds)):(seconds_value=date_seconds);

  const accountNo = tempUserId.concat(date_year,month_value, date_value, hours_value, minutes_value, seconds_value);
  //setUserId(accountNo);
  //setMemberInfo({...memberInfo, userId:accountNo});
  //return accountNo;
  //console.log("acc : ", accountNo);
  //setLocalStudy({...localStudy, id:accountNo});
  
  setLocalStudy({id:accountNo,instituteName:"", degree:"",studyYear:""});
}

  const handleStudyAdd =async(event)=>{
    event.preventDefault();
    if(localStudy.instituteName===""||localStudy.degree===""||localStudy.studyYear===""){
      enqueueSnackbar("Acadamic Fields cannot be empty. Enter all Fields!",{variant:'info'});
    }else{
      await setForm({...form, degreeDetails:[...form.degreeDetails, localStudy]});
      generateApplicationNo();
       // setLocalStudy({instituteName:"", degree:"",studyYear:""});
    }
  }

  const handleRemoveStudy=async(event, member)=>{
    event.preventDefault();
     //console.log("item : ", item);
      let tempCart = [...form.degreeDetails];
      tempCart = tempCart.filter(item => item.id !==member.id);
     // console.log("temp cart :", tempCart);
     await setForm({...form, degreeDetails:[...tempCart]});
     // calculateProgressiveValues(memberInfo);
     // setStudyDetails([...tempCart]);
  }

  return (
    <div>
    <Card>
      {!viewOnly?<Card.Body>
        <Form>
        <Form.Group as={Row} className="mb-3" controlId="formPlaintextTitle">
      <Form.Label column sm="6">Field Experience  </Form.Label>
        <Col sm="6">
        <Form.Select size="lg" style={{fontSize:16, borderRadius:10, padding:8, width:"100%"}}
                id="title"
                value={pastoral} 
                disabled={viewOnly}
                onChange={(event)=>{setPastoral(event.target.value)}}>
                  <option value="Pastoral">Pastoral </option>
                  <option value="Professional">Professional</option>
                  </Form.Select>
        </Col>
        </Form.Group>
        <h5>Academic information</h5>
          <Row>
            <Col> 
              <div className="navbar-bg" style={{marginBottom:'20px'}}>
                <FormGroup style={{overflow:"visible"}}>
              <div>
            <Row>
            <Col xs={9} lg={3}>
                <Form.Control
                    type="text"
                    name="institutionname"
                    placeholder="Name & Location of Institute"
                    onChange={(event)=>{setLocalStudy({...localStudy,
                      instituteName:(event.target.value).toUpperCase()})}}
                    value={localStudy.instituteName}
                />
                </Col>
                <Col xs={9} lg={3}>
                <Form.Control type="text" name="place"
                    placeholder='Degree/Diploma'
                    onChange={(event)=>{setLocalStudy({...localStudy,
                      degree:(event.target.value).toUpperCase()})}}
                    value={localStudy.degree}
                />
                </Col>
                <Col xs={9} lg={3}>
                  <Form.Control type="number" name="year" placeholder='Year of Study'
                    onChange={(event)=>{setLocalStudy({...localStudy,
                      studyYear:event.target.value})}}
                    value={localStudy.studyYear}
                  />
                 </Col>
                 <Col xs={9} lg={1}>
                    <Button title="Add User" onClick={(event)=>{handleStudyAdd(event)}} >ADD</Button>
                 </Col>
               </Row>
                  </div>
            </FormGroup>
       </div>
       </Col>           
        </Row>
        </Form>
      </Card.Body>:null}
        <p>Degree Details:</p>
        {form.degreeDetails ?<Table hover responsive >
        <thead className='theadStyle'>
            <tr>
            <th>
                #
            </th>
            <th>
            Name of the Institute
            </th>
            <th>
            Degree / Diploma
            </th>
            <th>
            Study Year
            </th>
            </tr>
        </thead>
        <tbody className='tbodyStyle'>
        {form.degreeDetails.map((item, index)=>
            <tr key={index} className='trStyle'>
            <th scope="row">
            {index+1}

            </th>
            <td>
            {item.instituteName}

            </td>
            <td>
                {item.degree}
            </td>
            <td>
                {item.studyYear}
            </td>
            {!viewOnly? <td>
              <i className="fa fa-trash-o me-2" aria-hidden="true"  onClick={(event)=>{handleRemoveStudy(event, item)}}> </i>
        </td>:null}
            </tr>)}
        </tbody>
        </Table>:<p>No Degree details found</p>}
    </Card>
    </div>
  )
}
