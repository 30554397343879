import React, {useEffect, useState, useContext} from 'react';
import { Row, Col, Card, Button, Breadcrumb, Form, ButtonGroup } from 'react-bootstrap';
import axios from "axios";
import { fetchAuthSession } from 'aws-amplify/auth';
import { Link } from "react-router-dom";
import { useSnackbar } from 'notistack';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { faHome } from '@fortawesome/free-solid-svg-icons';
import {Helmet} from 'react-helmet-async';
import RegistrationForm from './RegistrationForm';
import AcademicInfo from './AcademicInfo';
import LanguageInfo from './LanguageInfo';
import CertificateCopy from './CertificateCopy';
import MarkListCopy from './MarkListCopy';
import RecommendationLetter from './RecommendationLetter';
import { coursesOffered } from '../../ProData';
import Preloader from '../../components/Preloader/Preloader';
import { ProductContext } from '../../ProductProvider';
import ReactToPrint from "react-to-print";

const config = require('../../config.json');
const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

export default function NewRegistration() {
    const { loggedUser, generateDate, validateLogin } = useContext(ProductContext);
    const [profileDetails, setProfileDetails]=useState({applicantName:'', fatherName:'', dateOfBirth:'', gender:'Male', nationality:'',
    religiousStatus:'Scholastic', parishDiocese:'', congregationProvince:'', areaCode:'+91',mobileNumber:'', emailID:'', placeOfBirth:'', 
    birthDistrict:'', birthState:'', localAddress:'', localState:'', localPincode:'', localContact:'', perAddress:'', perState:'', perPincode:'',
    perContactNo:'', courseCategory:'Bachealor of Theology', degreeDetails:[], languageDetails:[], courseDetails:[]});
    const [loaded, setLoaded] = useState(true);
    const [imageFile, setImageFile] = useState('');
    const [certProofFile, setCertProofFile]=useState('');
    const [markListFile, setMarkListFile]=useState('');
    const [recommenLetterFile, setRecommenLetterFile]=useState('');
    const [app, setApp]=useState({appNo:'', appMsg:''});
    const { enqueueSnackbar } = useSnackbar();
    const [applicationProcessed, setApplicationProcessed]=useState(false);
    const componentRef = React.useRef(null);

    useEffect(()=>{
        if(loggedUser.isAuthenticated){
          resetValues();
        }else{
        validateLogin();
        resetValues();
        }
        return()=>{
          resetValues();
        }
      },[loggedUser])


      const reactToPrintContent = React.useCallback(() => {
        return componentRef.current;
      }, [componentRef.current]);
    
      const reactToPrintTrigger = React.useCallback(() => {
        return <Button variant="outline-primary"  size="sm">Print</Button>;
      }, []);
        
  
      const generateApplicationNo=()=>{
          const current_datetime = new Date();
          const fName=profileDetails.applicantName.trim().replace(/\s/g, "");
          //const tusername=memberInfo.name.trim().replace(/\s/g, "");
          const tfName= fName.substring(0,2).toUpperCase();
          const lName=profileDetails.fatherName.trim().replace(/\s/g, "");
          //const tusername=memberInfo.name.trim().replace(/\s/g, "");
          const tlName= lName.substring(0,2).toUpperCase();
          var tempUserId="";
          const date_year = (current_datetime.getFullYear()).toString().substring(2,4);
          var date_month = (current_datetime.getMonth() + 1).toString();
          var month_value="";
          date_month.length===1? (month_value=month_value.concat(0,date_month)):(month_value=date_month);
          var date_date = (current_datetime.getDate().toString());
          var date_value="";
          date_date.length===1?(date_value=date_value.toString().concat(0,date_date)):(date_value=date_date);
          var date_hours = (current_datetime.getHours().toString());
          var hours_value="";
          date_hours.length===1?(hours_value=hours_value.toString().concat(0,date_hours)):(hours_value=date_hours);
          var date_minutes = (current_datetime.getMinutes().toString());
          var minutes_value="";
          date_minutes.length===1 ?(minutes_value=minutes_value.toString().concat(0,date_minutes)):(minutes_value=date_minutes);
          const accountNo = tempUserId.concat(date_year,month_value, date_value, hours_value, minutes_value,tfName, tlName);
          //setUserId(accountNo);
          //setMemberInfo({...memberInfo, userId:accountNo});
          return accountNo;
          //console.log("acc : ", accountNo);
        
      }
    
        const resetValues=()=>{
          setProfileDetails({...profileDetails,applicantName:'', fatherName:'', dateOfBirth:'', gender:'Male', nationality:'',
          religiousStatus:'Scholastic', parishDiocese:'', congregationProvince:'', areaCode:'+91',mobileNumber:'', emailID:'', placeOfBirth:'', 
          birthDistrict:'', birthState:'', localAddress:'', localState:'', localPincode:'', localContact:'', perAddress:'', perState:'', perPincode:'',
          perContactNo:'', courseCategory:'Bachealor of Theology', degreeDetails:[], languageDetails:[], courseDetails:[]});
          setImageFile('');
          setMarkListFile('');
          setCertProofFile('');
          setRecommenLetterFile('');
        //  setIsEmailValidated(false);
         // setIsFormSubmitted(false);
         // initializeValues();
        }
  
        const validateFields=()=>{
          const {applicantName, fatherName, dateOfBirth, gender, birthDistrict, birthState, religiousStatus, parishDiocese, 
            congregationProvince, mobileNumber, emailID, placeOfBirth, localAddress, localState, 
            localPincode, nationality, localContact, perAddress, perState, perPincode, perContactNo, courseCategory,
            languageDetails, degreeDetails }=profileDetails;
            var returnValue="passed";
            if(!emailID.match(validRegex)){
                enqueueSnackbar("Email address is not in right format!",{variant:'warning'});
            returnValue="failed";
            }else  if(applicantName===""){
            enqueueSnackbar("Name cannot be empty!",{variant:'warning'}); 
          returnValue="failed";
        }else  if(dateOfBirth===""){
            enqueueSnackbar("Date of Birth cannot be Empty!",{variant:'warning'}); 
        returnValue="failed";
        }else  if(gender===""){
            enqueueSnackbar("Gender cannot be Empty!",{variant:'warning'}); 
        returnValue="failed";
        }else  if(religiousStatus===""){
            enqueueSnackbar("Religious Status cannot be Empty!",{variant:'warning'}); 
        returnValue="failed";
        }else  if(fatherName===""){
            enqueueSnackbar("Father Name cannot be Empty!",{variant:'warning'}); 
        returnValue="failed";
        }else  if(birthDistrict===""){
            enqueueSnackbar("District cannot be Empty!",{variant:'warning'}); 
        returnValue="failed";
        }else  if(mobileNumber===""){
            enqueueSnackbar("Mobile Number cannot be Empty!",{variant:'warning'}); 
        returnValue="failed";
        }else  if(emailID===""){
            enqueueSnackbar("Email Address cannot be Empty!",{variant:'warning'}); 
        returnValue="failed";
        }else  if(birthState===""){
            enqueueSnackbar("State cannot be Empty!",{variant:'warning'}); 
        returnValue="failed";
        }else  if(parishDiocese===""){
            enqueueSnackbar("Parish Diocese cannot be Empty!",{variant:'warning'}); 
        returnValue="failed";
        }else  if(congregationProvince===""){
            enqueueSnackbar("Congregation Province cannot be Empty!",{variant:'warning'}); 
        returnValue="failed";
        }else  if(nationality===""){
            enqueueSnackbar("Nationality cannot be Empty!",{variant:'warning'}); 
        returnValue="failed";
        }else  if(placeOfBirth===""){
            enqueueSnackbar("Place Of Birth cannot be Empty!",{variant:'warning'}); 
        returnValue="failed";
        }else  if(languageDetails===""){
            enqueueSnackbar("Language Details cannot be Empty!",{variant:'warning'}); 
        returnValue="failed";
        }else  if(localAddress===""||localState===""||localPincode===""||localContact===""){
            enqueueSnackbar("Local Address cannot be Empty!",{variant:'warning'}); 
        returnValue="failed";
        }else  if(perAddress===""||perState===""||perPincode===""||perContactNo===""){
            enqueueSnackbar("Permanent Address cannot be Empty!",{variant:'warning'}); 
        returnValue="failed";
        }else  if(degreeDetails===""){
            enqueueSnackbar("Degree Details cannot be Empty!",{variant:'warning'}); 
        returnValue="failed";
        }else  if(imageFile===""){
            enqueueSnackbar("Attach your Photo. Photo is must for registration!",{variant:'warning'}); 
        returnValue="failed";
        }else  if(markListFile===""){
            enqueueSnackbar("Attach Mark List Copy. It's mandatory for registration!",{variant:'warning'}); 
        returnValue="failed";
        }else  if(certProofFile===""){
            enqueueSnackbar("Attach UG/PG certificate. It's mandatory for registration.",{variant:'warning'}); 
        returnValue="failed";
        }else  if(recommenLetterFile===""){
            enqueueSnackbar("Attach Recommendation Letter. It's mandatory for registration.",{variant:'warning'}); 
        returnValue="failed";
        }else  {
          returnValue="passed";
        }
        return returnValue;
        }
  
        const postOnS3 = async(applicationno,timageFile,inputType)=>{
          try{
              //  console.log("NewImage : ", newImage);
              var tKeyName="";
              var newFileName="";
              if(inputType==="Profile"){
                tKeyName="/avatar/"+applicationno;   
                newFileName=applicationno+".jpeg";
              }else if (inputType==="Recomm"){
                tKeyName="/avatar/"+applicationno+"/docs";  
                newFileName=applicationno+"_recommLetter.jpeg"; 
              }else if (inputType==="CERTProof"){
                tKeyName="/avatar/"+applicationno+"/docs";  
                newFileName=applicationno+"_certproof.jpeg"; 
              }else if (inputType==="MarkList"){
                tKeyName="/avatar/"+applicationno+"/docs";  
                newFileName=applicationno+"_markproof.jpeg"; 
              }   
            
            //console.log("newfilename :", newFileName);
           // const newImage="";
            var file = timageFile;
            var blob = file.slice(0, file.size, 'image/jpeg'); 
            const newFile = new File([blob], newFileName, {type: 'image/jpeg'});
           // console.log("New file : ", newFile);
            //const session = await Auth.currentSession();
            //const access_token=session.idToken.jwtToken;
            const { idToken } = (await fetchAuthSession()).tokens ?? {};
            const bucketName=config.s3.bucketName + tKeyName;
           // await axios.get(`${config.api.invokeUrl}/profile_avatar/presigned/uploader?fileName=${newFileName}&bucketName=${bucketName}`,{
           // await axios.get(`${config.api.invokeURL}/images/gallery/newgallery?fileName=${newFileName}&bucketName=${bucketName}&keyName=${newKeyName}`,{
            await axios.get(`${config.api.invokeURL}/gallery/newgallery?fileName=${newFileName}&bucketName=${bucketName}`,{
                 headers:{
                     Authorization: idToken,
                     'x-api-key': config.api.key
                 }
             }).then(async(response)=>{
               //  console.log("Response : ", response);
                const res=await fetch(response.data.uploadURL, { method: 'PUT',
                 headers:{
                     "Content-Type":"image/jpeg"
                   },body: newFile});
                 //  console.log("Res : ", res);
                   if(res.status===200){
                  //  setNewProfileImage(newFileName);
                    //  fetchGalleryImages();
                      // setProfileImage(newFileName);
                       //var timestamp = new Date().getTime();     
                      // var tprofile = document.getElementById("profile");
                       //var imgsrc="";
                       //imgsrc=imgsrc.concat(config.s3.imageURL,newFileName,"?t=",timestamp);     
                       //tprofile.src=`${config.s3.imageURL}${newFileName}`+"?t="+timestamp;
                      // tprofile.src=imgsrc;
                      //tprofile.src="https://s3.ap-south-1.amazonaws.com/idntica.com/avatar/profileImage/defaultAvatar.jpeg";
                       //console.log("tprofle : ", tprofile.src);
                   }
              }).catch((error)=>{
                 // console.log("error1:", error);
                 enqueueSnackbar("Error Occured - " + error.message,{variant:'warning'}); 
               // setDispMsg(error.message);
               // hideLoader();
             })
          
          }catch(error){
           // console.log("error1:", error);
           enqueueSnackbar("Error Occured - " + error.message,{variant:'warning'}); 
          }
        
        }
  
      const handleSubmit=async(event)=>{
          event.preventDefault();
          try{
          const validationResult=validateFields();
          if(validationResult==="passed"){
            setLoaded(false);
            const {applicantName, fatherName, dateOfBirth, gender, birthDistrict, birthState, religiousStatus, parishDiocese, 
            congregationProvince, areaCode, mobileNumber, emailID, placeOfBirth, localAddress, localState, 
            localPincode, nationality, localContact, perAddress, perState, perPincode, perContactNo, courseCategory,
            languageDetails, degreeDetails }=profileDetails;
            const clientID = "VJCollege";
            const applicationno= await generateApplicationNo();
            const applicationDate=await generateDate();
            const applicationStatus="Pending Approval";
            const avatar= applicationno+".jpeg";
            const recommLetter=applicationno+"_recommLetter.jpeg"; 
            const certproofFile=applicationno+"_certproof.jpeg";  
            const markListFile=applicationno+"_markproof.jpeg";
            const tcourseStatus="Pending Approval";
            const tcoursePayment="Pending"; 
            var tcourseDetails=[];
            for(const i in coursesOffered){
              if(coursesOffered[i].courseName===courseCategory){
                tcourseDetails=coursesOffered[i];
              }
            }
            const { idToken } = (await fetchAuthSession()).tokens ?? {};
            const params={
              clientID:clientID, applicationNo: applicationno, applicationDate: applicationDate, applicationStatus:applicationStatus,
              applicantName:applicantName,fatherName:fatherName, birthDistrict:birthDistrict, birthState:birthState, dateOfBirth:dateOfBirth, gender:gender, religiousStatus:religiousStatus,
              parishDiocese:parishDiocese, congregationProvince:congregationProvince, placeOfBirth:placeOfBirth, localAddress:localAddress, areaCode:areaCode, mobileNumber:mobileNumber, emailID:emailID, localState:localState, localPincode:localPincode, localContact:localContact,
              perAddress:perAddress, nationality:nationality, perState:perState, perPincode:perPincode, perContactNo:perContactNo, avatar:avatar, recommLetter:recommLetter, markListFile:markListFile,
              certproofFile:certproofFile, courseStatus:tcourseStatus, coursePayment:tcoursePayment, courseCategory:courseCategory, degreeDetails:degreeDetails, languageDetails:languageDetails, courseDetails:tcourseDetails};
              //console.log("Params :", params);
            await axios.post(`${config.api.invokeURL}/dashboard/registration/${applicationno}`, params,{
              headers: {
                Authorization: idToken,
                'x-api-key':config.api.key
              }})
              .then(async(response)=>{
                //console.log("response : ", response); 
                await postOnS3(applicationno, imageFile,"Profile");
                await postOnS3(applicationno, recommenLetterFile,"Recomm");
                await postOnS3(applicationno, certProofFile,"CERTProof");
                await postOnS3(applicationno, markListFile,"MarkList");
                setApp({...app, appNo:applicationno, appMsg:"Congratulations and Your Application Registration is successful. Your application will be reviewed throughly and further updates will be shared via Email. You can also get the latest updates from our portal."})
                enqueueSnackbar(applicationno + " - Successfully Registered.",{variant:'success'}); 
                setApplicationProcessed(true);
                resetValues();
                setLoaded(true);
              }).catch((error)=>{
             //   console.log("error : ", error);
                enqueueSnackbar("Error Occured - " + error.message,{variant:'warning'}); 
                setLoaded(true);
              })
            }
            }catch(error){
             console.log("error : ", error);
             enqueueSnackbar("Error Occured - " + error.message,{variant:'warning'}); 
          setLoaded(true);
          }
        } 
      
        const handleNewForm=()=>{
          resetValues();
          setApp({...app, appNo:'', appMsg:''});
          setApplicationProcessed(false);
        }
  

  return (
    <div>
      {!loaded && <Preloader show={loaded ? false : true} />}
      <Helmet>
      <title>New Registration - Admin Office - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies</title>
      <meta name="description" content="New Registration - Admin Office - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies" />
      <meta name="keywords" content="New Registration - Admin Office - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies" /> 
      <link rel="canonical" href="/registration/new" />
    </Helmet>
    <Breadcrumb>
    <Breadcrumb.Item>
      <Link to="/">
      <i className="fa fa-home" aria-hidden="true"></i>
      </Link>
    </Breadcrumb.Item>
    <Breadcrumb.Item >
      Registration
    </Breadcrumb.Item>
    <Breadcrumb.Item >
      New
    </Breadcrumb.Item>
    </Breadcrumb>
    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
      <div className="d-block mb-4 mb-md-0">
        <h4>Application Form</h4>
      </div>
      <div className="btn-toolbar mb-2 mb-md-0"> 
        <ButtonGroup>
        <ReactToPrint
      content={reactToPrintContent}
      documentTitle="student"
      trigger={reactToPrintTrigger}
    />
       {/* <Button variant="outline-primary" size="sm" onClick={(event)=>{handleDownload(event)}}>Download</Button>
       } <CSVLink
            headers={downloadHeaders}
            filename="download.csv"
            data={searchResults}
            ref={csvLinkEl}
  />*/}
        </ButtonGroup>
      </div>
    </div>
   
    {!applicationProcessed  ? <div border="light" className="shadow-sm mb-4" ref={componentRef}>
            <RegistrationForm formType={"Edit"} profileDetails={profileDetails} setProfileDetails={setProfileDetails} setImageFile={setImageFile}/> 
            <AcademicInfo  formType={"Edit"} form={profileDetails} setForm={setProfileDetails} />
            <LanguageInfo formType={"Edit"} form={profileDetails} setForm={setProfileDetails} />
        <Card style={{padding:"0px"}}>
        <Card.Header>Attach below set of Documents:</Card.Header>
            <Row className="p-4">
                <Col md={4}>
                    <CertificateCopy fileName="test" setCertProofFile={setCertProofFile}/>
                </Col>
                <Col md={4}>
                    <MarkListCopy fileName="test" setMarkListFile={setMarkListFile}/>
                </Col>
                <Col md={4}>
                    <RecommendationLetter fileName="test" setRecommenLetterFile={setRecommenLetterFile}/>
                </Col>
            </Row>
        </Card>
        <div className="mt-3 text-center">
            <Button variant="primary" type="submit" onClick={(event)=>{handleSubmit(event)}}>Submit</Button>
          </div>
        </div>
        : <Card border="light" className=" container bg-white shadow-sm mb-4">
      <Card.Body>     
        <h5 className="mb-4">Registration - Final Status</h5>
        <Form>
        <Row>
        <h3 className="mb-4">Registration No - {app.appNo}</h3>
        </Row>
        <Row>
          <p>{app.appMsg}</p>
        </Row>
        <div className="mt-3 text-center">
            <Button variant="primary" type="submit" onClick={(event)=>{handleNewForm(event)}}> New Form</Button>
          </div>
        </Form>
        </Card.Body>
     </Card>}

    </div>
  )
}

