import React,{useEffect, useState, useContext} from 'react';
import {Tabs, Tab, Breadcrumb} from 'react-bootstrap';
import ListRegistrationStatus from './ListRegistrationStatus';
import { ProductContext } from '../../ProductProvider';
import { Link } from "react-router-dom";
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { faHome } from '@fortawesome/free-solid-svg-icons';
import {Helmet} from 'react-helmet-async';

export default function ManageRegistration() {
    const [tabValue, setTabValue]=useState("Pending");
    const [applicationDetails, setApplicationDetails]=useState([]);
    const { validateLogin, loggedUser} = useContext(ProductContext);

    useEffect(() => {
      if(!loggedUser.isAuthenticated){
        validateLogin();
      }
    }, [loggedUser]) 

  return (
    <div>
    <Helmet>
      <title>Manage Registration - Admin Office - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies</title>
      <meta name="description" content="Manage Registration - Admin Office - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies" />
      <meta name="keywords" content="Manage Registration - Admin Office - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies" /> 
      <link rel="canonical" href="/registration/manage" />
    </Helmet>
    <Breadcrumb>
    <Breadcrumb.Item>
      <Link to="/">
      <i class="fa fa-home" aria-hidden="true"></i>
      </Link>
    </Breadcrumb.Item>
    <Breadcrumb.Item >
      Registration
    </Breadcrumb.Item>
    <Breadcrumb.Item >
      Manage
    </Breadcrumb.Item>
    </Breadcrumb>
    <h3>Registration Management</h3>
    <Tabs
      defaultActiveKey="Pending"
      id="fill-tab-example"
      className="mb-3"
      fill onSelect={(k) => setTabValue(k)}
    >
      <Tab eventKey="Pending" title="Pending">
      {tabValue==="Pending"?<ListRegistrationStatus applicationDetails={applicationDetails} setApplicationDetails={setApplicationDetails} status="Pending Approval"/>:null}
      </Tab>
      <Tab eventKey="Approved" title="Approved">
      {tabValue==="Approved"?<ListRegistrationStatus applicationDetails={applicationDetails} setApplicationDetails={setApplicationDetails} status="Approved"/>:null}
      </Tab>
      <Tab eventKey="Declined" title="Declined">
      {tabValue==="Declined"?<ListRegistrationStatus applicationDetails={applicationDetails} setApplicationDetails={setApplicationDetails} status="Declined"/>:null}
      </Tab>
    </Tabs>
    </div>
  )
}
