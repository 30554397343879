import React from 'react';
import { Card } from 'react-bootstrap';

export default function FileAttachments(props) {
    const {markListFile, recommenLetterFile, certProofFile}=props;
    
  return (
      <>
    <div>FileAttachments</div>
    <Card>
        <p>UG/PG Certificate:</p>
    {certProofFile? <img src={certProofFile} alt="CERT Proof"/>:<p>No Files Attached</p>}
    </Card>
    <Card>
        <p>Recommendation Letter:</p>
    {recommenLetterFile?<img src={recommenLetterFile} alt="Recommendation Letter"/>:<p>No Files Attached</p>}
    </Card>
    <Card>
        <p>Mark List Copy:</p>
    {markListFile?<img src={markListFile} alt="MarkList"/>:<p>No Files Attached</p>}
    </Card>
    </>
  )
}
