import React,{useEffect, useContext, useState} from 'react';
import logo from '../assets/images/logo/COLLEGELOGO.jpeg';
import { ProductContext } from '../ProductProvider';
import Preloader from '../components/Preloader/Preloader';

export default function LandingPage() {
  const { validateLogin, loggedUser} = useContext(ProductContext);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if(!loggedUser.isAuthenticated){
      validateLogin();
    }
    setLoaded(true);
    //console.log("logged user : ", loggedUser);
  }, [loggedUser])

  return (
    <>
          {!loaded && <Preloader show={loaded ? false : true} />}
    <div style={{marginTop:'5%', marginBottom:'auto'}}>
      <div className="text-center">
        <div className="">
          <div className="dashboard-sec">
    <h1 style={{align:"center"}}>Welcome to Vidyajyoti College</h1>
        <img src={logo} width="150" className="shadow-light rounded-circle"/>
    <h1 align="center" variant="h1">Admin Portal</h1>
    </div>
    </div>
    </div>
    </div>
    </>
  )
}