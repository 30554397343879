import React, { useEffect, useContext, useState } from 'react';
import { Form, Button, Col, Row, Breadcrumb, Card} from 'react-bootstrap';
import axios from "axios";
import { useSnackbar } from 'notistack';
//import Header from '../../components/header/Header';
import { ProductContext } from '../../ProductProvider';
import { phoneCode } from '../../ProData';
import logo from '../../assets/images/logo/COLLEGELOGO.jpeg';
import ProfileImage from '../../components/profile/ProfileImages';
import Preloader from '../../components/Preloader/Preloader';
import '../../styles.css';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { faHome } from '@fortawesome/free-solid-svg-icons';
import {Helmet} from 'react-helmet-async';
import { Link } from "react-router-dom";
import { fetchAuthSession } from 'aws-amplify/auth';

const config = require('../../config.json');
const dobRegex=/([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/;
const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
const phoneRegex=/^\+[1-9]{1}[0-9]{3,14}$/;

const StaffForm = (props) => {
  const {formType, handleCloseForm } = props;
  const { enqueueSnackbar } = useSnackbar();
  const { loggedUser, validateLogin, generateDate, adminDetails, setAdminDetails} = useContext(ProductContext);
  const [loaded, setLoaded] = useState(false);
  const [viewOnly, setViewOnly] = useState(true);
  const [profileImage, setProfileImage] = useState(logo);
  const [newProfileImage, setNewProfileImage]=useState(logo)
  const [titleMsg, setTitleMsg] = useState('');
  const [subTitleMsg, setSubTitleMsg] = useState('');
  const [newImage, setNewImage]=useState('');
  const inputRef = React.useRef();
  const triggerFileSelectPopup = () => inputRef.current.click();

  useEffect(()=>{

    if(loggedUser.isAuthenticated){
      //console.log("Props :", props);
      if(formType==="New"){
        resetValues();
        setTitleMsg('Create User');
        setSubTitleMsg('New Staff Registration');
        setViewOnly(false);
        setLoaded(true);
      }else if(formType==="View"){
        setLoaded(false);
        const clientID=loggedUser.familyName;
       // console.log("selected Item :", props.selectedItem);
       // console.log("image : ", `${config.s3.imageURL}${clientID}${"/admins/"}${props.selectedItem.adminID}${"/"}${props.selectedItem.adminID}${".jpeg"}`);
        setProfileImage(`${config.s3.imageURL}${"avatars/admins/"}${adminDetails.userID}${".jpeg"}`);
       // setProfileImage(`${config.s3.imageURL}${clientID}${"/admins/"}${props.selectedItem.adminID}${"/"}${props.selectedItem.adminID}${".jpeg"}`);
        setViewOnly(true);
        //setAdminDetails(props.selectedItem);
        setTitleMsg('Admin User');
        setSubTitleMsg('View Admin User');
        setLoaded(true);
        //console.log("props : ", props);
      }else if(formType==="Edit"){
        setLoaded(false);
        const clientID=loggedUser.familyName;
       setProfileImage(`${config.s3.imageURL}${"avatars/admins/"}${adminDetails.userID}${".jpeg"}`);
       setViewOnly(false);
        //setAdminDetails(props.selectedItem);
        setTitleMsg('Admin User');
        setSubTitleMsg('Edit Admin User');
        setLoaded(true);
      }else{
      fetchMyProfile();
      }
      //console.log("props : ", props);
    }else{
      validateLogin();
    }
  },[loggedUser])

const fetchMyProfile=async()=>{
    setLoaded(false);
    try{
          //const session = await Auth.currentSession();
          //const access_token=session.idToken.jwtToken;
          const { idToken } = (await fetchAuthSession()).tokens ?? {};
          const searchOption="UserID";
          const clientID=loggedUser.website;
          //console.log("logged user : " , loggedUser);
          await axios.get(`${config.api.invokeURL}/dashboard/officeform?fetchKey=${loggedUser.nickname}&fetchOption=${searchOption}&clientID=${clientID}`, {
            headers: {
              Authorization: idToken,
              'x-api-key':config.api.key
            }
          }).then(async(response) => {
           //console.log("response : ", response.data[0]);
            if(response.data.length>0)
            {
              setAdminDetails(response.data[0]);
              await setProfileImage(`${config.s3.imageURL}${"avatars/admins/"}${response.data[0].userID}${".jpeg"}`);
              //console.log((`${config.s3.imageURL}${"avatars/admins/"}${response.data[0].userID}${".jpeg"}`));
                //console.log("image : ", `${config.s3.imageURL}${clientID}${"/admins/"}${response.data[0].adminID}${"/"}${response.data[0].adminID}${".jpeg"}`);
              enqueueSnackbar("Profile loaded Successfully!",{variant : 'success'});
              setLoaded(true);
            }
            setLoaded(true);
        }).catch((error)=>{
        enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});
        setLoaded(true);
      })
    }catch(error){
    enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});
    setLoaded(true);
  }
}

const generateSubscriberID=()=>{
    const current_datetime = new Date();
    //const fName=profileDetails.firstName.trim().replace(/\s/g, "");
    //const tfName= fName.substring(0,2).toUpperCase();
    //const lName=profileDetails.lastName.trim().replace(/\s/g, "");
    //const tlName= lName.substring(0,2).toUpperCase();
    var tempUserId="";
    const date_year = (current_datetime.getFullYear()).toString().substring(2,4);
    var date_month = (current_datetime.getMonth() + 1).toString();
    var month_value="";
    date_month.length===1? (month_value=month_value.concat(0,date_month)):(month_value=date_month);
    var date_date = (current_datetime.getDate().toString());
    var date_value="";
    date_date.length===1?(date_value=date_value.toString().concat(0,date_date)):(date_value=date_date);
    var date_hours = (current_datetime.getHours().toString());
    var hours_value="";
    date_hours.length===1?(hours_value=hours_value.toString().concat(0,date_hours)):(hours_value=date_hours);
    var date_minutes = (current_datetime.getMinutes().toString());
    var minutes_value="";
    date_minutes.length===1 ?(minutes_value=minutes_value.toString().concat(0,date_minutes)):(minutes_value=date_minutes);
    var date_seconds = (current_datetime.getSeconds().toString());
    var seconds_value="";
    date_seconds.length===1 ?(seconds_value=seconds_value.toString().concat(0,date_seconds)):(seconds_value=seconds_value);
    const accountNo = tempUserId.concat(date_year,month_value, date_value, hours_value, minutes_value,seconds_value);
    return accountNo;
}

const resetValues=()=>{
    const todayDate= generateDate();
    //const userid= generateSubscriberID();
    setAdminDetails({...adminDetails,
        userID:'', title:'Mr',userName:'', areaCode:'+91', 
            userPhoneNo:'', userEmailID:'', userRole:'Faculty', userAccount:'Residence', joiningDate:'', userStatus:'Active', bloodGroup:'', currentAddress:'', dateOfBirth:'', addtionalDetails:''});  
}

const handleCreateAccount=async(event)=>{
    event.preventDefault();
    try{
        //console.log("Logged User :", loggedUser);
      const validationResult=validateFields("Add");
      if(validationResult==="passed"){
          setLoaded(false);
          const { idToken } = (await fetchAuthSession()).tokens ?? {};
          const todayDate=await generateDate();
          //const clientID=loggedUser.familyName;
          //const userid= generateSubscriberID();
          const {userName, userEmailID, title, joiningDate, areaCode, userPhoneNo, userStatus, userRole, bloodGroup, currentAddress, dateOfBirth, userAccount, addtionalDetails} = adminDetails; 
          const params={
            userEmailID:userEmailID,
            userName:userName,
            title:title,
            registeredDate:todayDate,
            joiningDate:joiningDate,
            areaCode:areaCode,
            userPhoneNo:userPhoneNo,
            userRole:userRole,
            userStatus:userStatus,
            userAccount:userAccount,
            updatedBy:loggedUser.name,
            updatedOn:todayDate,
            dateOfBirth:dateOfBirth,
            currentAddress:currentAddress,
            bloodGroup:bloodGroup,
            addtionalDetails:addtionalDetails
          }
          //console.log("Params :", params);
        await axios.post(`${config.api.invokeURL}/dashboard/officeform`,params, {  
          headers: {
            Authorization: idToken,
            'x-api-key':config.api.key
          }
        }).then(async(response) => {
         //console.log("Response : ", response);
         const tempuserid=JSON.parse(response.data);
         if(response.status===215){
          enqueueSnackbar(response.data,{variant:'warning'});
          }else if(response.status===201){
          if(newImage){
              var uploadStatus="Failed";
              uploadStatus= await postOnS3(tempuserid);  
            }    
            //console.log("test search : ", testSearchResult);
            setLoaded(true)
            enqueueSnackbar("Account Created successfully!",{variant:'success'});
          }
          setLoaded(true)
      }).catch((error)=>{
        //console.log("Error : ", error);
      enqueueSnackbar("Error Occured - " + error.message,{variant:'warning'});
      setLoaded(true);
      window.scrollTo(0,0);
    })
  }
    }catch(error){
      //console.log("Error : ", error);
    enqueueSnackbar("Error Occured - " + error.message,{variant:'warning'});
    setLoaded(true);
    window.scrollTo(0,0);
    }
    window.scrollTo(0,0);
}

const postOnS3 = async(fileName)=>{
  try{
    const newFileName=fileName+".jpeg";      
    var file = newImage;
    var blob = file.slice(0, file.size, 'image/jpeg'); 
    const newFile = new File([blob], newFileName, {type: 'image/jpeg'});
    console.log("New file : ", newFile);
    //const session = await Auth.currentSession();
    //const access_token=session.idToken.jwtToken;   
    //const clientID=loggedUser.familyName;
    const { idToken } = (await fetchAuthSession()).tokens ?? {};
    const bucketName=config.s3.bucketName;
    var keyName="images/avatars/admins/";
    const fileType="image/jpeg";
    await axios.get(`${config.api.invokeURL}/presigned/uploader?fileName=${newFileName}&bucketName=${bucketName}&keyName=${keyName}&fileType=${fileType}`,{
    //await axios.get(`${config.api.invokeURL}/presigned/uploader?fileName=${newFileName}&bucketName=${bucketName}`,{
          headers:{
              Authorization: idToken,
              'x-api-key': config.api.key
          }
      }).then(async(response)=>{
        // console.log("Response : ", response);
        enqueueSnackbar("Image successfully uploaded",{variant:'success'});
        const res=await fetch(response.data.uploadURL, { method: 'PUT',
          headers:{
              "Content-Type":"image/jpeg"
            },body: newFile});
          //  console.log("Res : ", res);
            if(res.status===200){
            setNewProfileImage(newFileName);
            }
      }).catch((error)=>{
          //console.log("error1:", error);
          enqueueSnackbar("Error Occured - " + error.message,{variant:'warning'});
          return "Failed";
        // setDispMsg(error.message);
        // hideLoader();
      })
  }catch(error){
  //  console.log("error1:", error);
  enqueueSnackbar("Error Occured - " + error.message,{variant:'warning'});
  return "Failed";
  }
return "Success";
}

const handleSelectFile = async(event)=>{
    event.preventDefault();
    if (event.target.files && event.target.files.length > 0) {
      setLoaded(false);
      setNewImage(event.target.files[0]);
      var tprofile = document.getElementById("profile");
      tprofile.src=URL.createObjectURL(event.target.files[0]);
      setProfileImage(tprofile.src);
     setLoaded(true);
    }
}

const validateFields=(mode)=>{
      var returnValue="passed";
      var tphone="";
      //console.log("mode :", mode);
      //console.log("new Image", newImage);
      if(adminDetails.areaCode!==""&& adminDetails.userPhoneNo!==""){
      tphone=adminDetails.areaCode.toString()+adminDetails.userPhoneNo.toString();
      }
      if(adminDetails.userName===""){
        enqueueSnackbar("Name is mandatory.",{variant : 'info'});
        returnValue="failed";
      }else if(adminDetails.joiningDate===""){
        enqueueSnackbar("Registration Date is mandatory.",{variant : 'info'});
        returnValue="failed";
      }else if(!adminDetails.joiningDate.match(dobRegex)){
        enqueueSnackbar("Registration Date is not in proper format.",{variant : 'info'});
        returnValue="failed";
      }else if(adminDetails.userEmailID===""){
        enqueueSnackbar("Email Address is mandatory.",{variant : 'info'});
        returnValue="failed";
      }else if(!adminDetails.userEmailID.match(validRegex)){
        enqueueSnackbar("Email address is not in right format!.",{variant : 'info'});
        returnValue="failed";
      }else if(!tphone.match(phoneRegex)){
        enqueueSnackbar("Area Code and Phone Number is not in right format!.",{variant : 'info'});
        returnValue="failed";
      }else if(adminDetails.areaCode==="" || adminDetails.userPhoneNo===""){
        enqueueSnackbar("Area Code and Mobile Number is mandatory!.",{variant : 'info'});
        returnValue="failed";
      }else if(mode==="Add" && !newImage){
        enqueueSnackbar("Add Your Photo!",{variant : 'info'});
        returnValue="failed";
      }else {
    returnValue="passed";
    }
  return returnValue;
}

const handleEditAccount=async(event)=>{
  event.preventDefault();
  try{
  const validationResult=validateFields("Edit");
  if(validationResult==="passed"){
    setLoaded(false);
    const todayDate=await generateDate();
    //console.log("logged User :", loggedUser);
    //const clientID=loggedUser.familyName;
    //const session = await Auth.currentSession();
    //const access_token=session.idToken.jwtToken;
    const { idToken } = (await fetchAuthSession()).tokens ?? {};
    const {userID, userName, userEmailID, title, joiningDate, areaCode, userPhoneNo, userStatus, userRole, bloodGroup, currentAddress, dateOfBirth, userAccount, addtionalDetails} = adminDetails; 
    const params={
        userID:userID,
      userEmailID:userEmailID,
      userName:userName,
      title:title,
      registeredDate:todayDate,
      joiningDate:joiningDate,
      areaCode:areaCode,
      userPhoneNo:userPhoneNo,
      userRole:userRole,
      userStatus:userStatus,
      userAccount:userAccount,
      updatedBy:loggedUser.name,
      updatedOn:todayDate,
      dateOfBirth:dateOfBirth,
      currentAddress:currentAddress,
      bloodGroup:bloodGroup,
      addtionalDetails:addtionalDetails
    }
console.log("Params : ", params);
  await axios.patch(`${config.api.invokeURL}/dashboard/officeform`,params, {  
      headers: {
        Authorization: idToken,
        'x-api-key':config.api.key
      }})
      .then(async(response)=>{
        console.log("response : ", response); 
        if(newImage){
        await postOnS3(userID);
        }
        enqueueSnackbar("Admin Account Updated successfully!",{variant:'success'});
        const id=adminDetails.userID;
        const {searchResults} = props;
        const objIndex=searchResults.findIndex((obj)=>obj.userID===id);
        searchResults[objIndex].userEmailID=userEmailID;
        searchResults[objIndex].userName=userName;
        searchResults[objIndex].title=title;
        searchResults[objIndex].joiningDate=joiningDate;
        searchResults[objIndex].areaCode=areaCode;
        searchResults[objIndex].userPhoneNo=userPhoneNo;
        searchResults[objIndex].userRole=userRole;
        searchResults[objIndex].userStatus=userStatus;
        searchResults[objIndex].userAccount=userAccount;
        searchResults[objIndex].dateOfBirth=dateOfBirth;
        searchResults[objIndex].currentAddress=currentAddress;
        searchResults[objIndex].bloodGroup=bloodGroup;
        searchResults[objIndex].addtionalDetails=addtionalDetails;
        //handleCloseForm();
      window.scrollTo(0,0);
      setLoaded(true);
      }).catch((error)=>{
        enqueueSnackbar("Error Occured - " + error.message,{variant:'warning'});
        window.scrollTo(0,0);
        setLoaded(true);
      })
  }
    }catch(error){
      enqueueSnackbar("Error Occured - " + error.message,{variant:'warning'});
      window.scrollTo(0,0);
      setLoaded(true);
  }
}

  return (
    <div m="20px">
    <Helmet>
      <title>Staff Registration - Admin Office - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies</title>
      <meta name="description" content="Staff Registration - Admin Office - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies" />
      <meta name="keywords" content="Staff Registration - Admin Office - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies" /> 
      <link rel="canonical" href="/staff" />
    </Helmet>
    <Breadcrumb>
    <Breadcrumb.Item>
      <Link to="/">
      <i className="fa fa-home" aria-hidden="true"></i>
      </Link>
    </Breadcrumb.Item>
    <Breadcrumb.Item >
      Staff
    </Breadcrumb.Item>
    <Breadcrumb.Item >
      Registration
    </Breadcrumb.Item>
  </Breadcrumb>

      {!loaded && <Preloader show={loaded ? false : true} />}
      {/*<Header subtitle={subTitleMsg} />*/}
      {profileImage && loaded && <ProfileImage profileImage={profileImage} formType={formType} setImageFile={setNewImage}/>}

      <Card style={{padding:"0px"}}>
      <Card.Body>
        <Form>
          <Row>
          <Col sm={2} className="mb-3">
              <Form.Group id="areanumber">
              <Form.Label>Title</Form.Label>
              <Form.Select size="lg" style={{fontSize:16, borderRadius:10, padding:8}}
                id="title"
                value={adminDetails.title}
                disabled={viewOnly}
                onChange={(event)=>{setAdminDetails({...adminDetails, title:event.target.value})}}>               
                <option value="Mr">Mr</option>
                <option value="Mrs">Mrs</option>
                <option value="Ms">Ms</option>
                <option value="Sr">Sr</option>
                <option value="Br">Br</option>
                <option value="Fr">Fr</option>
                <option value="Other">Other</option>
                </Form.Select>
            </Form.Group>
            </Col>
            <Col lg={10} className="mb-3">
              <Form.Group id="firstName">
                <Form.Label>Full Name</Form.Label>
                <Form.Control required type="text" placeholder="Enter your name...." value={adminDetails.userName}
                  disabled={viewOnly}
                  onChange={(event)=>{setAdminDetails({...adminDetails, userName:(event.target.value).toUpperCase()})}}  
               />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col lg={6} className="mb-3">
              <Form.Group id="emal">
                <Form.Label>Email</Form.Label>
                <Form.Control required type="email" value={adminDetails.userEmailID}
                  disabled={viewOnly} placeholder='Enter your email address...'
                  onChange={(event)=>{setAdminDetails({...adminDetails, userEmailID:(event.target.value).toUpperCase()})}}
                  />
              </Form.Group>
            </Col>
            <Col lg={6} className="mb-3">
            <Form.Label>Mobile Number</Form.Label>
            <Row>
              <Col sm={4} className="mb-3">
              <Form.Group id="areanumber">
                <Form.Select size="lg" style={{fontSize:16, borderRadius:10, padding:8, width:"100%"}}
                type="select" name="courseName"
                                  value={adminDetails.areaCode} 
                                  disabled={viewOnly}
                                  onChange={(event)=>{setAdminDetails({...adminDetails, areaCode:event.target.value})}}
                                >
                                  {phoneCode.map(item => {
                                return (<option key={item.Code} value={item.Code}>{item.Code}</option>);
                              })}
                  </Form.Select>
                 </Form.Group>
            </Col>
             <Col sm={8} className="mb-3">
              <Form.Group id="mnumber">
                <Form.Control required type="number" placeholder="Enter your mobile number...." 
                                disabled={viewOnly}
                                value={adminDetails.userPhoneNo} onChange={(event)=>{setAdminDetails({...adminDetails, userPhoneNo:event.target.value})}}
                />
              </Form.Group>
            </Col>
            </Row>
            </Col>    
          </Row>
          <Row className="align-items-center">
            <Col lg={4} className="mb-3">
              <Form.Group id="birthday">
                <Form.Label>Date Of Birth</Form.Label>
                <Form.Control type="date" value={adminDetails.dateOfBirth}
                                  disabled={viewOnly}
                                  onChange={(event)=>{setAdminDetails({...adminDetails, dateOfBirth:event.target.value})}}
               />
              </Form.Group>
            </Col>
            <Col lg={4} className="mb-3">
              <Form.Group id="joiningDate">
                <Form.Label>Associated From</Form.Label> <br />
                <Form.Control type="date" value={adminDetails.joiningDate}
                                  disabled={viewOnly}
                                  onChange={(event)=>{setAdminDetails({...adminDetails, joiningDate:event.target.value})}}
               />
              </Form.Group>
            </Col>
            <Col lg={4} className="mb-3">
              <Form.Group id="joiningDate">
                <Form.Label>Province</Form.Label> <br />
                <Form.Control type="text" value={adminDetails.province}
                                  disabled={viewOnly}
                                  onChange={(event)=>{setAdminDetails({...adminDetails, province:event.target.value})}}
               />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col lg={4} className="mb-3">
              <Form.Group id="status">
                <Form.Label>Status</Form.Label> <br />
                <Form.Select size="lg" style={{fontSize:16, borderRadius:10, padding:8, width:"100%"}}
                labelId="status-label"
                id="status"
                variant="filled"
                value={adminDetails.userStatus}
                disabled={viewOnly}
                onChange={(event)=>{setAdminDetails({...adminDetails, userStatus:event.target.value})}}                >
                <option value="Active">Active</option>
                <option value="In-Active">In-Active</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col lg={4} className="mb-3">
              <Form.Group id="status">
                <Form.Label>Role</Form.Label> <br />
                <Form.Select size="lg" style={{fontSize:16, borderRadius:10, padding:8, width:"100%"}}
                labelId="status-label"
                id="status"
                variant="filled"
                value={adminDetails.userRole}
                disabled={viewOnly}
                onChange={(event)=>{setAdminDetails({...adminDetails, userRole:event.target.value})}}                >
                <option value="Faculty">Faculty</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col lg={4} className="mb-3">
              <Form.Group id="bgroup">
                <Form.Label>Blood Group</Form.Label>
                <Form.Control required type="text" value={adminDetails.bloodGroup}
                  disabled={viewOnly} placeholder='Enter your blood group....'
                  onChange={(event)=>{setAdminDetails({...adminDetails, bloodGroup:(event.target.value).toUpperCase()})}}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
          <Col lg={6} className="mb-3">
              <Form.Group id="religion">
                <Form.Label>Account Type</Form.Label> <br />
                <Form.Select size="lg" style={{fontSize:16, borderRadius:10, padding:8}}
                id="title"
                value={adminDetails.userAccount}
                disabled={viewOnly}
                onChange={(event)=>{setAdminDetails({...adminDetails, userAccount:event.target.value})}}>               
                <option value="Residence">Residence</option>
                <option value="College">College</option>
                </Form.Select>
              </Form.Group>
              </Col>
          <Col lg={6} className="mb-3">
              <Form.Group id="religion">
                <Form.Label>Current Address</Form.Label> <br />
                <Form.Control type="text" value={adminDetails.currentAddress}
                                  disabled={viewOnly}
                                  onChange={(event)=>{setAdminDetails({...adminDetails, currentAddress:event.target.value})}}
               />
              </Form.Group>
              </Col>
              </Row>
              <Row>
              <Form.Group id="denomination">
                <Form.Label>Addtional Details</Form.Label>
                <Form.Control required as="textarea" rows={5} value={adminDetails.addtionalDetails}
                  disabled={viewOnly} placeholder='Enter your parish & diocese details....'
                  onChange={(event)=>{setAdminDetails({...adminDetails, addtionalDetails:event.target.value})}}
                />
              </Form.Group>
          </Row>
        </Form>
    </Card.Body>
</Card>

    <div style={{display:"flex", justifyContent:"center", mt:"20px"}}>
        {formType==="New"?<Button type="submit" className='buttonStyle' onClick={event=>handleCreateAccount(event)}>
        Save
        </Button>:formType==="Edit"?<Button type="submit" className='buttonStyle' onClick={event=>handleEditAccount(event)}>
        Update
        </Button>:null}
        {formType==="New"?null:<Button type="submit" className='buttonStyle' onClick={event=>handleCloseForm(event)}>
        Cancel
        </Button>}
    </div>
    </div>
  );
};

const phoneRegExp = /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

export default StaffForm;
