import React, { useState, useEffect } from 'react';
//import { VJTodayList } from '../../ProData';
import axios from "axios";
import { useSnackbar } from 'notistack';
import {Table, Button, Modal, Breadcrumb} from 'react-bootstrap';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { faEye, faBook, faEdit, faHome } from '@fortawesome/free-solid-svg-icons';
import Header from "../../components/header/Header";
import AddPublications from './AddPublications';
import ViewPDF from './ViewPDF';
import Preloader from '../../components/Preloader/Preloader';
import '../../styles.css';
import {Helmet} from 'react-helmet-async';
import { Link } from "react-router-dom";
//import { fetchAuthSession } from 'aws-amplify/auth';

const config = require('../../config.json');

export default function VJToday() {
  const [loaded, setLoaded] = useState(true);
  const [rowModesModel, setRowModesModel] = React.useState({});
  const [titleName, setTitleName] = useState('Publications');
  const [subTitleName, setSubTitleName] = useState('VJToday');
  const [viewForm, setViewForm] = useState(false);
  const [selectedItem, setSelectedItem] = useState({id:'', issue:'', title:'',issueYear:'', issueMonth:'',pubStatus:'Active', description:'NA', pubCategory:'VJ Today', pubPrice:'0', intlPrice:'0', pages:'0', publisherName:'VJ Today', ratings:'', regDate:'', startDate:'', endDate:'', authorName:'VJ Today'});
  const [searchResult, setSearchResult] = useState([]);
  const [formType, setFormType] = useState("View");
  const [ showPDF, setShowPDF] = useState(false);
  const [pdfFile, setPdfFile]=useState(null);

  const handleCloseForm=()=>{
    setViewForm(false);
  }
  const handleOpenForm=()=>setViewForm(true);
  const { enqueueSnackbar } = useSnackbar();

  const handleShowPDF=()=>{
    setShowPDF(true);
  }

  const handleClosePDF=()=>{
    setShowPDF(false);
  }

  /*const columns = [
    { field: "id", headerName: "ID" },
    { field: "issue", headerName: "Issue Name" , flex: 1},
    {
      field: "issueYear",
      headerName: "Issue Year",
      flex: 0.5,
      cellClassName: "name-column--cell",
    },
    {
      field: "issueMonth",
      headerName: "Issue Month",
      headerAlign: "left",
      align: "left",
    },{
      field: "pubStatus",
      headerName: "Status",
      headerAlign: "left",
      align: "left",
    },
    
    {
        field: 'actions',
        type: 'actions',
        headerName: 'Actions',
        width: 100,
        cellClassName: 'actions',
        getActions: ({ id }) => {
          const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
  
          if (isInEditMode) {
            return [
              <GridActionsCellItem
                icon={<SaveIcon />}
                label="Save"
                sx={{
                  color: 'primary.main',
                }}
                //onClick={handleSaveClick(id)}
              />,
              <GridActionsCellItem
                icon={<CancelIcon />}
                label="Cancel"
                className="textPrimary"
                //onClick={handleCancelClick(id)}
                color="inherit"
              />,
            ];
          }
  
          return [
            <GridActionsCellItem
            icon={<VisibilityIcon />}
            label="Edit"
            className="textPrimary"
            onClick={event=>handleProjectView(event, id, "View")}
            color="inherit"
          />,
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              className="textPrimary"
              onClick={event=>handleProjectView(event, id, "Edit")}
              color="inherit"
            />,
            <GridActionsCellItem
              icon={<AutoStoriesIcon />}
              label="Delete"
              onClick={event=>handleFetchPDF(event, id)}
              //onClick={event=>handleProjectDelete(event, id)}
              color="inherit"
            />,
          ];
        },
      }
  ];*/

  useEffect(() => {
    fetchPublicationList();
  }, [])

  const fetchPublicationList =async()=>{
    try{
        setLoaded(false);
        //const session = await Auth.currentSession();
        //const access_token=session.idToken.jwtToken;
      //const fileName='images/gallery/';
     // const bucketName="images.stjosephhospitaldindigul.in";
    //await axios.get(`${config.api.invokeUrl}/images/gallery?fileName=${keyName}&bucketName=${bucketName}`,{
    //const fileName='images/doctor/';
    const fetchOption="PubStatus";
    const fetchKey="Active";
    await axios.get(`${config.api.invokeURL}/dashboard/publications?fetchOption=${fetchOption}&fetchKey=${fetchKey}`,{
        headers: {
          //Authorization: access_token,
          'x-api-key': config.api.key
        }}, 
    ).then((response)=>{
        //console.log("Response : ", response);
        setSearchResult(response.data.Items);
        const finalList=[];  
        // console.log(finalList);
        enqueueSnackbar(response.data.Items.length + " - active records found in VJToday",{variant:'success'})
        setLoaded(true);
        window.scrollTo(0,0); 
    }).catch((error)=>{
        //console.log(error.message);
        enqueueSnackbar("Error Occured - " + error.message,{variant:'warning'}) 
        setLoaded(true);
        window.scrollTo(0,0); 
    })
}catch(error){
   // setDispMsg(error.message);
    enqueueSnackbar("Error Occured - " + error.message,{variant:'warning'});
    setLoaded(true);
    window.scrollTo(0,0); 
}
setLoaded(true);
}

const handleFetchPDF = async(e, id) => {
  e.preventDefault();
  const itemtest=searchResult.filter((image)=>image.id===id);
  const item=itemtest[0];
  //console.log("fetch : ", item);
  try{
    const fetchFile=item.id+".pdf";
    //console.log("fetch file :", fetchFile);
    const bucketName='docs.vidyajyoticollege.in/pdfs/vjtoday';
    //const session = await Auth.currentSession();
    //const access_token=session.idToken.jwtToken;
    await axios.get(`${config.api.invokeURL}/presigned/static?fileName=${fetchFile}&bucketName=${bucketName}`,{
      headers: {
        //Authorization: access_token,
        'x-api-key': config.api.key
      }}, 
   ).then(async(response)=>{
        console.log("Response : ", response);
        setPdfFile(response.data.downloadURL);
        setShowPDF(true); 

        /*await axios.get(response.data.downloadURL,{
          responseType:'arraybuffer',
          headers: {
            "Content-Type": "application/json",
            "Accept":"application/pdf",
            //"content-disposition": "attachment; filename=test.pdf",
            "access-control-allow-origin" : "*",
          }
        })
        .then(function(pdfresponse){
         console.log("pdf response : ", pdfresponse);
         let blob = new Blob([pdfresponse.data], {type: 'application/pdf'});
         const link = document.createElement('a');
         link.href = URL.createObjectURL(blob);
         console.log("link.href : ", link);
        // setPdfFile(pdfresponse.data);
        // setShowPDF(true); 

        })*/
    }).catch((error)=>{
        //console.log("Error :", error);
      enqueueSnackbar("Error Occured - " + error.message,{variant:'warning'});
    })        
  }catch(error){
    //console.log("Error :", error);
    enqueueSnackbar("Error Occured - " + error.message,{variant:'warning'});
  }
}

  const handleProjectView=async(e, id,mode)=>{
    e.preventDefault();
    const itemtest=searchResult.filter((image)=>image.id===id);
    const item=itemtest[0];
    await setSelectedItem(item);
    //console.log("key : ", item);
    setFormType(mode);
    handleOpenForm();
    //console.log("TFoldername : ", tfoldername);
  }

  const handleNew = (e)=>{
    e.preventDefault();
    setFormType("New");
    handleOpenForm();
  }

  return (
  <div >
    {!loaded && <Preloader show={loaded ? false : true} />}
    <Helmet>
      <title>InfoDesk - Admin Office - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies</title>
      <meta name="description" content="InfoDesk - Admin Office - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies" />
      <meta name="keywords" content="InfoDesk - Admin Office - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies" /> 
      <link rel="canonical" href="/infodesk" />
    </Helmet>
    <Breadcrumb>
    <Breadcrumb.Item>
      <Link to="/">
      <i className="fa fa-home" aria-hidden="true"></i>
      </Link>
    </Breadcrumb.Item>
    <Breadcrumb.Item >
      Publication
    </Breadcrumb.Item>
    <Breadcrumb.Item >
      VJToday
    </Breadcrumb.Item>
  </Breadcrumb>
  <h3>VJ Publications</h3>

    {/*Add */}
    <Modal show={viewForm} onHide={handleCloseForm} maxWidth={'md'} fullWidth>
    <Modal.Header closeButton>
      <Modal.Title> Add New Publications</Modal.Title>
    </Modal.Header>
      <Modal.Body>
          <div style={{width:"100%"}}>
            <AddPublications formType={formType} selectedItem={selectedItem} setSelectedItem={setSelectedItem} handleCloseForm={handleCloseForm}
            fetchPublicationList={fetchPublicationList}/>
        </div>
      </Modal.Body>
        {/*<DialogActions>
        <Box display="flex" justifyContent="end" mt="20px">
              <Button type="submit" color="secondary" variant="contained" onClick={event=>handleCloseForm(event)}>
                Cancel
              </Button>
            </Box>
  </DialogActions>*/}
    </Modal>

    {/*View */}
    <Modal show={showPDF} onHide={handleClosePDF} size='lg' >
    <Modal.Header closeButton>
    <Modal.Title sx={{ m: 0, p: 2 }} id="customized-dialog-title">
       View Publications</Modal.Title>
       </Modal.Header>
        <Modal.Body dividers>
          <div style={{width:"100%"}}>
            <ViewPDF pdfFile={pdfFile} handleClosePDF={handleClosePDF}/>
</div>
      </Modal.Body>
    </Modal>
    <div>
    {/*<Header title={titleName} subtitle={subTitleName} />*/}
      {/*<Typography variant="h5" align="center" color="text.secondary" paragraph>
        Something short and leading about the collection below—its contents,
        the creator, etc. Make it short and sweet, but not too short so folks
        don&apos;t simply skip over it entirely.
      </Typography>
      <Stack
        sx={{ pt: 4 }}
        direction="row"
        spacing={2}
        justifyContent="center"
      >
        <Button variant="contained">Main call to action</Button>
        <Button variant="outlined">Secondary action</Button>
  </Stack>*/}
    </div>
    <div style={{padding:"20px"}}>
      <Button className='buttonStyle' type="submit" 
      onClick={handleNew}>
        New
      </Button>
    </div>
    <div className='card table-wrapper'>
       {loaded && <Table striped bordered hover>
        <thead className='theadStyle'>
            <tr>
                <th>#</th>
                <th>Category</th>
                <th>Issue Name</th>
                <th>Issue Year</th>
                <th>Issue Month</th>
                <th>Status</th>
                <th>Actions</th>
            </tr>
        </thead>
        <tbody className='tbodyStyle'>
            {searchResult.map((item,index)=>(
                <tr className='trStyle' key={index}>
                    <td>{index+1}</td>
                    <td>{item.title}</td>
                    <td>{item.issue}</td>
                    <td>{item.issueYear}</td>
                    <td>{item.issueMonth}</td>
                    <td>{item.pubStatus}</td>
                    <td> <div style={{display: 'flex', flexDirection:'row'}}>                
                    <div style={{paddingLeft:10}}>
                    <i className="fa fa-eye me-2" aria-hidden="true" onClick={event=>handleProjectView(event, item.id, "View" )}></i></div>
                    <div style={{paddingLeft:10}}>
                    <i className="fa fa-pencil me-2" aria-hidden="true" onClick={event=>handleProjectView(event, item.id, "Edit")}></i></div>
                    <div style={{paddingLeft:10}}>
                    <i className="fa fa-book me-2" aria-hidden="true" onClick={event=>handleFetchPDF(event, item.id)}></i></div>
                    </div>
                    </td>
                </tr>
            ))}
        </tbody>
        </Table>}
    </div>
  </div>
 )
}
