import React from 'react';
import { useSnackbar } from 'notistack';
import Resizer from "react-image-file-resizer";

export default function RecommendationLetter(props) {
    const inputRef = React.useRef();
    const {setRecommenLetterFile }=props;
    const { enqueueSnackbar } = useSnackbar();

   const handleFileSelection = async(event)=>{
     event.preventDefault();
     if (event.target.files && event.target.files.length > 0) {
      try {
        await Resizer.imageFileResizer(
          event.target.files[0],
          1500,
          1500,
          "JPEG",
          100,
          0,
          (uri) => {
            //console.log(uri);
            setRecommenLetterFile(uri);
            //console.log("compressed");
           // this.setState({ newImage: uri });
          },
          "blob",
          400,
          400
        );
      } catch (err) {
        console.log(err);
      }
      //setRecommenLetterFile(event.target.files[0]);
       //console.log("selected file : ", event.target.files[0].name);
      // var tprofile = document.getElementById("profile");
      // tprofile.src=URL.createObjectURL(event.target.files[0]);
      enqueueSnackbar("File Selected - "+ event.target.files[0].name,{variant:'info'});
   }else{
    enqueueSnackbar("No File Selected",{variant:'info'});
   }
  }

    return (
    <div style={{textAlign:"left"}}> 
      <p>Recommedation Letter : </p>
        <input
          type="file"
          accept="image/*"
          ref={inputRef}
          onChange={handleFileSelection}
        />
    </div>     
    )

}