import React from 'react';
import {Card, CardGroup, Container, Button, CardFooter} from 'react-bootstrap';
//import AddSlider from './AddSlider';
import logo from '../../assets/images/logo/COLLEGELOGO.jpeg'
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { faTrash} from '@fortawesome/free-solid-svg-icons';
//import '../../styles.css';

//import Header from "../../components/Header";

const config = require('../../config.json');
  
export default function ImageDisplay(props) {
    const {galleryImages, titleName, subTitleName, handleDeleteImage, handleOpen } = props;  
    //const theme = useTheme();
    //const colors = tokens(theme.palette.mode);
    
  return (
    <>
      <main>
        <Container maxWidth="md" style={{padding:"20px"}}>
            <Button type="submit" className='buttonStyle'
              onClick={handleOpen}>
                New
            </Button>
            </Container>
        <Container >
          <div className='row'> 
          <CardGroup>
            {galleryImages.map((item, index) => (
                <div className="col grid-container" key={index}>
                <Card key={index} border="primary">
                    <Card.Img variant="top" src={`${config.s3.invokeURL}${item.fullPathName}`} style={{ width: '100%' }} />
                  <CardFooter style={{ textAlign:'center' }} >
                  <i className="fa fa-trash-o" aria-hidden="true" onClick={event=>handleDeleteImage(event, item.fullPathName)}></i>
                  </CardFooter>
                </Card>
                </div>
            ))}
            </CardGroup>
            </div>
        </Container>
      </main>
    </>
  );
}