import React from "react";
import {Form, Row, Col} from 'react-bootstrap';

export default function EditGallery(props) {
    const {newProjectName, newProjectDate, setNewProjectName, setNewProjectDate }=props;
  return (
    <>
    <div m="50px">
    <Form.Group as={Row} className="mb-3" controlId="formPlaintextFile">
        <Form.Label column sm="4"> Gallery Name </Form.Label>
        <Col sm="8">
        <Form.Control type="text" placeholder="Enter Gallery Name" 
        controlId="formGalleryName"
        onChange={event=>setNewProjectName(event.target.value)}
        value={newProjectName} />
        </Col>
    </Form.Group>
    <Form.Group as={Row} className="mb-3" controlId="formPlaintextDate">
        <Form.Label column sm="4">Date</Form.Label>
        <Col sm="8">
        <Form.Control type="date" placeholder="Enter Gallery Date" 
        controlId="formGalleryDate"
        onChange={event=>setNewProjectDate(event.target.value)}
        value={newProjectDate} />
        </Col>
    </Form.Group>
   </div>
   </>
)
}
