import React, { useState, useContext } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import { ProductContext } from '../../ProductProvider';
import Preloader from '../../components/Preloader/Preloader';
import { useSnackbar } from 'notistack';
import Resizer from "react-image-file-resizer";

export default function AddImage(props) {
    const { handleImageError } = useContext(ProductContext)
    const { setImageFile, profileImage, formType, preloaded}=props;
    //const [loaded, setLoaded] = useState(true);
    const [images, setImages]=useState([]);
    const { enqueueSnackbar } = useSnackbar();

   const handleFileSelection =async (event)=>{
     event.preventDefault();
    // setLoaded(false);
     if (event.target.files && event.target.files.length > 0 && event.target.files.length < 6) {
     // const selectedFIles =[];
      //const targetFiles =event.target.files;
      //const targetFilesObject= [...targetFiles]
      //targetFilesObject.map((file)=>{
        // return selectedFIles.push(URL.createObjectURL(file))
      //})
      try {
        await Resizer.imageFileResizer(
          event.target.files[0],
          1500,
          1500,
          "JPEG",
          100,
          0,
          (uri) => {
            //console.log(uri);
            setImageFile(uri);
            //console.log("compressed");
           // this.setState({ newImage: uri });
          },
          "blob",
          400,
          400
        );
      } catch (err) {
        console.log(err);
      }
   
      //setImageFile(event.target.files);
      //setImages(selectedFIles);
   }else if(event.target.files.length > 6){
    enqueueSnackbar("Maximum only 5 files can be attached!",{variant : 'warning'});
   }
 //  setLoaded(true);
  }

    return (
    <div  style={{textAlign:"center"}}> 
      <Preloader show={preloaded ? false : true} />
       <div style={{ textAlign:'center', justifyContent:'center', marginBottom:'10px'}}>
         {images.length>0?
          <div className="row">
          {images.map((url, index)=>{
              return (
                  <div className="col-sm-3" key={index}>
                  <div className="card">
                  <img src={url} alt="new images" style={{width:"6rem", height:"6rem", marginBottom:'15px', borderRadius:'8px', border:'2px solid lightgrey'}}/>
                  </div>
                  </div>
              )
          })}
          </div>:
          <img  id="profile" src={profileImage} 
            alt="Profile" style={{width:"12rem", height:"12rem", marginBottom:'15px', borderRadius:'8px', border:'2px solid lightgrey'}} />}
          <Form.Group as={Row} className="mb-3" controlId="formPlaintextImage">
          <Form.Label column sm="4">
            Select Image
          </Form.Label>
          <Col sm="8">
          <Form.Control type="file"
            controlId="formFileImage"  
            accept="image/*" 
            //multiple
            disabled={formType==="View"?true:false}
            onError={handleImageError}
            onChange={handleFileSelection} />
          </Col>
        </Form.Group>

          {/*}  <input
          type="file"
          accept="image/*"
          multiple
          onChange={handleFileSelection}
          onError={handleImageError}
          style={{textAlign:'center', justifyContent:'center', borderTopRightRadius:'8px', borderBottomRightRadius:'8px', backgroundColor:'white', border:'2px solid lightblue' }}
          disabled={formType==="View"?true:false}
        />*/}
       </div>
    </div>  
  )
}