import React, {useState, useEffect, useContext} from 'react';
//import { Alert } from 'reactstrap';
import { Col, Row, Card, Form, Button } from 'react-bootstrap';
import axios from "axios";
import { fetchAuthSession } from 'aws-amplify/auth';
import { useSnackbar } from 'notistack';
import Preloader from '../../components/Preloader/Preloader';
import { ProductContext } from '../../ProductProvider';

const config = require('../../config.json');

export default function DeactivateModal(props) {
    const {deactivateToggle, profileDetails} = props;
    const { loggedUser, generateDate } = useContext(ProductContext);
    const [newRemarks, setNewRemarks] =useState('');
    const [loaded, setLoaded]=useState(true);
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
      //console.log("props :", props);
    
    }, [])
    

    const processDeactivation=async(event)=>{
        event.preventDefault();
        try{
            setLoaded(false);
              const tDate= await generateDate();
           const { idToken } = (await fetchAuthSession()).tokens ?? {};
           const {clientID, applicationNo, registrationNo }=profileDetails;
           const params={
            clientID:clientID, applicationNo: applicationNo, registrationNo: registrationNo, 
            updatedBy:loggedUser.name, updatedOn:tDate, newRemarks:newRemarks
            };
        
            //console.log("Params :", params);
        
            await axios.patch(`${config.api.invokeURL}/dashboard/student/deactivate`, params,{
                  headers: {
                    Authorization: idToken,
                    'x-api-key':config.api.key
                  }})
                  .then(async(response)=>{
                    enqueueSnackbar("Successfully Deactivated",{variant:'success'}); 
                    deactivateToggle();
                    setLoaded(true);            
                  })
                  .catch((error)=>{
                    enqueueSnackbar("Error Occured - " + error.message,{variant:'warning'}); 
                    setLoaded(true);
                  })
            
          }catch(error){
            enqueueSnackbar("Error Occured - " + error.message,{variant:'warning'}); 
              setLoaded(true);
          }
    }

  return (
    <>
      {!loaded && <Preloader show={loaded ? false : true} />}
      <Card border="light" className=" bg-white shadow-sm mb-4">
       <Card.Body>     
         <Form>
         <Row>
         <Form.Group id="currentEmail">
         <Form.Label>Registration Number</Form.Label>
        <Form.Control type="text" value={profileDetails.registrationNo}
            disabled />
            </Form.Group>
         </Row>
         <Row>
         <Form.Group id="currentEmail">
         <Form.Label>Name</Form.Label>
        <Form.Control type="text" value={profileDetails.applicantName}
            disabled />
            </Form.Group>
         </Row>
         <Row>
         <Form.Group id="newEmail">
        <Form.Label>Remarks for Discontinue</Form.Label>
        <Form.Control type="text" name="newEmail"
        value={newRemarks} onChange={(event)=>{setNewRemarks(event.target.value)}}/>
        </Form.Group>
         </Row>
         <div className="mt-3 text-center">
             <Button variant="primary" type="submit" onClick={(event)=>{processDeactivation(event)}}>De-Activate</Button>
           </div>

         </Form>
         </Card.Body>     

    </Card>
    </>
  )
}
